import { BaseService } from './base.service'

export class VisiteService extends BaseService {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super()
  }

  // FAQ QUESTIONS

  updateVisite(object) {
    const { id, data } = object
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/visites/${id}`, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  searchVisites(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/visites/search', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

}

export default new VisiteService()
