<template>
  <section class="invoice-preview-wrapper">
    <!-- <div>
      {{ editorData }}
      <ckeditor
        v-model="editorData"
        :editor="editor"
        :config="editorConfig"
      />
    </div>
    <hr class="hr-primary"> -->
    <!-- -------------------------------------------------------- -->
    <div v-if="!isFetchingFormDetails && !validOutput">
      <h1>{{ $t('form_details.not_exist') }}</h1>
    </div>
    <content-loader
      v-if="isFetchingFormDetails"
      view-box="0 0 400 460"
      :speed="2"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <circle cx="29" cy="30" r="17" />
      <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
      <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
      <rect x="-7" y="60" rx="2" ry="2" width="408" height="132" />
    </content-loader>
    <!-- statues -->
    <!-- validOutput.statuses.length = {{validOutput && validOutput.statuses && validOutput.statuses.length}} <br> -->
    <div
      v-if="reversedStatuses.length > 0"
      class="col-md-12 horizontal-scroll nopadding"
    >
      <!-- <span v-if="!isLoading_statuses_section"> -->

        <span
          v-for="(status, status_key) in reversedStatuses"
          :key="status_key"
          class=""
        >
          <!-- <i v-if="status_key > 0" class="las la-arrow-left iconMedium pt-2"></i> -->
          <span v-if="status_key > 0">----</span>
          <b-badge :variant="variant_for_timeline(status.name)">
            <span class="d-flex flex-row align-items-center">
              <span :class="status.reason ? 'col-11' : 'col-12'">
                {{ $t(statusMatching(states, status.name)) }} <br />
                <small style="">
                  {{ status.created_at }}
                  {{
                    status.user &&
                    ' par ' + status.user.lastname + ' ' + status.user.firstname
                  }}
                  {{ status.reason && ', C' }}
                </small>
              </span>
              <span v-if="status.reason" class="col-1 d-flex align-item-center">
                <i
                  v-b-tooltip.hover.left="$t(status.reason && status.reason)"
                  class="las la-pencil-alt iconMedium"
                />
              </span>
            </span>
          </b-badge>
        </span>

      <!-- </span> -->
      <!-- <span v-else class="text-center"><b-spinner small /></span> -->
    </div>
    <!-- / statues end -->
    <!-- content -->
    <div
      v-if="!isFetchingFormDetails && validOutput"
      :class="
        show_vizualisation_section ? 'main-grid-file-display' : 'main-grid'
      "
    >
      <!-- <div cols="12" xl="12" md="12">line</div> -->
      <!-- current content -->
      <div cols="12" xl="12" md="12" class="position-relative w-100">
        <b-card no-body class="invoice-preview-card">
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div>
                <AppLogo :width="210" :height="110" />
              </div>
              <div class="mt-md-0 d-flex flex-row-reverse">
                <div class="col-12 text-right mt-1">
                  <small>
                    <span
                      @click.stop="
                        copyToClipboard(validOutput && validOutput.reference)
                      "
                    >
                      Ref:
                      <strong>
                        {{ validOutput && validOutput.reference }}</strong
                      >
                    </span>
                    <br />
                    O1 BP 2037 <br />
                    Cotonou, Bénin <br />
                    Tel : +229 21 30 70 13 <br />
                    +229 21 30 70 10 <br />
                    mtca.sp@gouv.bj
                  </small>
                </div>
              </div>
            </div>
          </b-card-body>
          <component
            :is="selectedForm.vueComponents.details"
            :valid-output="validOutput"
            :show-check-buttons="canShowCheckButtons"
            :can-update="can_update"
            :can-update-request-data="can_update_resquest_data"
            @on_change_check_object="object_check_change($event)"
            @on_change_object="object_change($event)"
            @on_would_see_file="would_see_file($event)"
          />
        </b-card>
      </div>
      <!-- for print current content -->
      <vue-html2pdf
        v-if="validOutput"
        ref="html2Pdf"
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="10"
        :filename="`DEMANDE_${validOutput.reference}`"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        :pdf-margin="10"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        class="d-content"
        @progress="onProgress($event)"
        @hasDownloaded="onDownloadedRequestFolder($event)"
      >
        <section slot="pdf-content">
          <b-col cols="12" xl="12" md="12" class="" style="font-size: 11px">
            <b-card no-body class="invoice-preview-card">
              <b-card-body class="invoice-padding pb-0">
                <div
                  class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                >
                  <div>
                    <AppLogo :width="200" :height="100" />
                  </div>
                  <div class="mt-md-0 d-flex flex-row-reverse">
                    <div class="col-12 text-right mt-1">
                      <small style="font-size: 12px">
                        Ref:
                        <strong>
                          {{ validOutput && validOutput.reference }}</strong
                        >
                        <br />
                        O1 BP 2037 <br />
                        Cotonou, Bénin <br />
                        Tel : +229 21 30 70 13 <br />
                        +229 21 30 70 10 <br />
                        mtca.sp@gouv.bj
                      </small>
                    </div>
                  </div>
                </div>
              </b-card-body>
              <component
                :is="selectedForm.vueComponents.details"
                :valid-output="validOutput"
              />
            </b-card>
          </b-col>
        </section>
      </vue-html2pdf>
      <!-- modal pv -->
      <b-modal
        id="modal-pv"
        ref="modal-pv"
        hide-backdrop
        ok-only
        no-close-on-backdrop
        content-class="shadow"
        :title="$t('form_details.print_pv')"
        :ok-title="$t('form_details.modal_pv')"
        size="lg"
        centered
        @ok="downloadPDF(`modal-pv`)"
      >
        <!-- pv -->
        <component
          :is="selectedForm.vueComponents.pv"
          :data="validOutput"
          :reference="validOutput.reference"
        />
        <!-- <hr class="invoice-spacing" /> -->
        <!-- checklist -->
        <component
          :is="selectedForm.vueComponents.checklist"
          :data="checklist_object_for_print"
          :for-print="forPrint"
          :signature="signature"
          :reference="validOutput.reference"
        />
      </b-modal>
      <!-- pv & checklist for print -->
      <vue-html2pdf
        v-show="false"
        ref="printablePV"
        class="border rounded"
        :show-layout="false"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        :manual-pagination="true"
        :filename="`PV_${validOutput.reference}`"
        :pdf-quality="2"
        :pdf-margin="0"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
      >
        <section slot="pdf-content">
          <!-- pv -->
          <section class="pdf-item">
            <component
              :is="selectedForm.vueComponents.pv"
              :data="validOutput"
              :reference="validOutput.reference"
            />
          </section>

          <!-- <hr class="invoice-spacing" /> -->

          <!-- checklist -->
          <section class="pdf-item">
            <component
              :is="selectedForm.vueComponents.checklist"
              :data="checklist_object_for_print"
              :for-print="forPrint"
              :signature="signature"
              :reference="validOutput.reference"
            />
          </section>
        </section>
      </vue-html2pdf>
      <!-- modal checklist -->
      <b-modal
        id="modal-checklist"
        ref="modal-checklist"
        hide-backdrop
        ok-only
        no-close-on-backdrop
        content-class="shadow"
        :title="$t('form_details.modal_checklist')"
        ok-title="$t('form_details.modal_pv')"
        size="lg"
        centered
        @ok="downloadPDF(`modal-checklist`)"
      >
        <component
          :is="selectedForm.vueComponents.checklist"
          :data="validOutput.checklist_object"
          @on_change_checklist_object="object_checklist_change($event)"
        />
      </b-modal>
      <!-- checklist for print -->
      <vue-html2pdf
        v-show="false"
        ref="printableChecklist"
        class="border rounded"
        :show-layout="true"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :manual-pagination="true"
        :filename="`Checklist_${validOutput.reference}`"
        :pdf-quality="2"
        :pdf-margin="0"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
      >
        <section slot="pdf-content">
          <component
            :is="selectedForm.vueComponents.checklist"
            :data="checklist_object_for_print"
            :for-print="forPrint"
            :reference="validOutput.reference"
          />
        </section>
      </vue-html2pdf>
      <!-- /sidebar -->
    </div>

    <!-- modal treatment agent MTCA-->
    <b-modal
      id="modal-set-treatment-agent"
      ref="modal-set-treatment-agent"
      ok-only
      content-class="shadow"
      title="Désigner l'agent responsable du traitement de la demande au niveau du MTCA."
      size="lg"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <b-row>
        <b-col md="12">
          <b-form-group :label="$t('form_details.change_processing_agent')">
            <v-select
              v-model="treatment_agent_id"
              label="label"
              :reduce="(option) => option.id"
              :options="[...options_mtca_admins, ...options_mtca_agents]"
              placeholder="Choisissez un utilisateur du MTCA"
            />
          </b-form-group>
        </b-col>
        <!-- buttons -->
        <b-col md="12">
          <span class="d-flex justify-content-end">
            <b-button
              variant="gradient-primary"
              class="btn-icon"
              size="sm"
              @click="
                () => {
                  update_treatment_agent()
                }
              "
            >
              <b-spinner v-if="isLoading_update_treatment_agent" small />
              <i v-else class="las la-check" />
              Enregistrer le nouveau agent traitant
            </b-button>
          </span>
        </b-col>
      </b-row>
    </b-modal>
    <!-- modal treatment agent OT-->
    <b-modal
      id="modal-set-treatment-agent-ot"
      ref="modal-set-treatment-agent-ot"
      ok-only
      content-class="shadow"
      title="Désigner l'agent responsable du traitement de la demande au niveau de l'Organe Technique."
      size="lg"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <b-row>
        <b-col md="12">
          <b-form-group label="Changer l'agent de traitement de la demande">
            <v-select
              v-model="treatment_agent_id_ot"
              label="label"
              :reduce="(option) => option.id"
              :options="[...options_ot_admins, ...options_ot_agents]"
              placeholder="Choisissez un utilisateur du l'OT"
            />
          </b-form-group>
        </b-col>
        <!-- buttons -->
        <b-col md="12">
          <span class="d-flex justify-content-end">
            <b-button
              variant="gradient-primary"
              class="btn-icon"
              size="sm"
              @click="
                () => {
                  update_treatment_agent_ot()
                }
              "
            >
              <b-spinner v-if="isLoading_update_treatment_agent" small />
              <i v-else class="las la-check" />
              Enregistrer le nouveau agent traitant
            </b-button>
          </span>
        </b-col>
      </b-row>
    </b-modal>
    <!-- modal meet technical visit -->
    <b-modal
      id="modal-meet-with-applicant"
      ref="modal-meet-with-applicant"
      ok-only
      content-class="shadow"
      title="Responsable(s) de l'entretien avec le requérant."
      size="lg"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <b-row>
        <b-col md="12">
          <b-form-group label="Responsable de l'entretien avec le requérant">
            <v-select
              v-model="applicant_responsable_id"
              label="label"
              :reduce="(option) => option.id"
              :options="[
                ...options_ot_agents,
                ...options_auditors,
                ...options_ot_admins,
                ...options_mtca_agents,
              ]"
              placeholder="Choisissez un utilisateur"
            />
          </b-form-group>
        </b-col>
        <!-- buttons -->
        <b-col md="12">
          <span class="d-flex justify-content-end">
            <b-button
              variant="gradient-primary"
              class="btn-icon"
              size="sm"
              @click="
                () => {
                  storeMeet_applicant_responsable()
                }
              "
            >
              <b-spinner v-if="isLoading_update_rdv_technical_visit" small />
              <i v-else class="las la-check" />
              Enregistrer le responsable de l'entretien avec le requérant
            </b-button>
          </span>
        </b-col>
      </b-row>
    </b-modal>
    <!-- modal meet technical visit -->
    <b-modal
      id="modal-morality-investigation"
      ref="modal-morality-investigation"
      ok-only
      content-class="shadow"
      title="Responsable(s) de l'enquete de moralite."
      size="lg"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <b-row>
        <b-col md="12">
          <b-form-group label="Responsable de l'enquete de moralite.">
            <v-select
              v-model="morality_investigation_responsable_id"
              label="label"
              :reduce="(option) => option.id"
              :options="[
                ...options_ot_agents,
                ...options_auditors,
                ...options_ot_admins,
                ...options_mtca_agents,
              ]"
              placeholder="Choisissez un utilisateur"
            />
          </b-form-group>
        </b-col>
        <!-- buttons -->
        <b-col md="12">
          <span class="d-flex justify-content-end">
            <b-button
              variant="gradient-primary"
              class="btn-icon"
              size="sm"
              @click="
                () => {
                  storeMeet_morality_investigation_responsable()
                }
              "
            >
              <b-spinner v-if="isLoading_update_rdv_technical_visit" small />
              <i v-else class="las la-check" />
              Enregistrer le responsable de l'enquete de moralite.
            </b-button>
          </span>
        </b-col>
      </b-row>
    </b-modal>
    <!-- modal meet technical visit -->
    <b-modal
      id="modal-meet-technical-visit"
      ref="modal-meet-technical-visit"
      ok-only
      content-class="shadow"
      title="Responsable(s) de la visite technique."
      size="lg"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <b-row>
        <b-col md="12">
          <b-form-group :label="$t('form_details.technical_visitor')">
            <v-select
              v-model="technical_visitor_id"
              label="label"
              :reduce="(option) => option.id"
              :options="[
                ...options_ot_agents,
                ...options_auditors,
                ...options_ot_admins,
                ...options_mtca_agents,
              ]"
              :placeholder="$t('form_details.choose_user')"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group :label="$t('form_details.followers_technical_visitor')">
            <v-select
              v-model="followers_technical_visitor_ids"
              label="label"
              :reduce="(option) => option.id"
              :options="
                removeItemById(
                  [
                    ...options_ot_agents,
                    ...options_auditors,
                    ...options_ot_admins,
                    ...options_mtca_agents,
                  ],
                  technical_visitor_id
                )
              "
              :placeholder="$t('form_details.choose_users')"
              multiple
            />
          </b-form-group>
        </b-col>
        <!-- buttons -->
        <b-col md="12">
          <span class="d-flex justify-content-end">
            <b-button
              variant="gradient-primary"
              class="btn-icon"
              size="sm"
              @click="
                () => {
                  storeMeet_technical_visit()
                }
              "
            >
              <b-spinner v-if="isLoading_update_rdv_technical_visit" small />
              <i v-else class="las la-check" />
              {{ $t('form_details.rdv_technical_visit') }}
            </b-button>
          </span>
        </b-col>
      </b-row>
    </b-modal>
    <!-- modal meet classification visit -->
    <b-modal
      id="modal-meet-classification-visit"
      ref="modal-meet-classification-visit"
      ok-only
      content-class="shadow"
      :title="$t('form_details.modal_meet_classification_visit')"
      size="lg"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <b-row>
        <b-col md="12">
          <b-form-group :label="$t('form_details.classification_visit')">
            <v-select
              v-model="classification_visitor_id"
              label="label"
              :reduce="(option) => option.id"
              :options="[
                ...options_ot_agents,
                ...options_auditors,
                ...options_ot_admins,
                ...options_mtca_agents,
              ]"
              :placeholder="$t('form_details.choose_user')"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            :label="$t('form_details.followers_classification_visitor')"
          >
            <v-select
              v-model="followers_classification_visitor_ids"
              label="label"
              :reduce="(option) => option.id"
              :options="
                removeItemById(
                  [
                    ...options_ot_agents,
                    ...options_auditors,
                    ...options_ot_admins,
                    ...options_mtca_agents,
                  ],
                  classification_visitor_id
                )
              "
              :placeholder="$t('form_details.choose_users')"
              multiple
            />
          </b-form-group>
        </b-col>
        <!-- buttons -->
        <b-col md="12">
          <span class="d-flex justify-content-end">
            <b-button
              variant="gradient-primary"
              class="btn-icon"
              size="sm"
              @click="
                () => {
                  storeMeet_classification_visit()
                }
              "
            >
              <b-spinner
                v-if="isLoading_update_rdv_classification_visit"
                small
              />
              <i v-else class="las la-check" />
              {{ $t('form_details.rdv_classification_visit') }}
            </b-button>
          </span>
        </b-col>
      </b-row>
    </b-modal>
    <!-- modal meet audit -->
    <b-modal
      id="modal-meet-audit"
      ref="modal-meet-audit"
      ok-only
      content-class="shadow"
      :title="$t('form_details.meet_audit')"
      size="lg"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <b-row>
        <b-col md="12">
          <b-form-group :label="$t('form_details.audit_responsible')">
            <v-select
              v-model="auditor_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="label"
              :reduce="(option) => option.id"
              :options="[
                ...options_auditors,
                ...options_ot_agents,
                ...options_mtca_agents,
              ]"
              :placeholder="$t('form_details.auditor')"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group :label="$t('form_details.followers_auditor')">
            <v-select
              v-model="followers_auditor_id"
              label="label"
              :reduce="(option) => option.id"
              :options="
                removeItemById(
                  [
                    ...options_auditors,
                    ...options_ot_agents,
                    ...options_mtca_agents,
                  ],
                  auditor_id
                )
              "
              :placeholder="$t('form_details.choose_users')"
              multiple
            />
          </b-form-group>
        </b-col>
        <!-- buttons -->
        <b-col md="12">
          <span class="d-flex justify-content-end">
            <b-button
              variant="gradient-primary"
              class="btn-icon"
              size="sm"
              @click="
                () => {
                  storeMeet_audit()
                }
              "
            >
              <b-spinner v-if="isLoading_update_rdv_technical_visit" small />
              <i v-else class="las la-check" />
              {{ $t('form_details.register_auditor') }}
            </b-button>
          </span>
        </b-col>
      </b-row>
    </b-modal>
    <!-- results modals -------------------------------------------->
    <!-- audit modal -->
    <b-modal
      id="modalMeetingWithApplicant"
      ref="modalMeetingWithApplicant"
      ok-only
      content-class="shadow"
      :title="$t('form_details.news_applicant')"
      centered
      size="xl"
      hide-footer
      no-close-on-backdrop
    >
      <div>
        <validation-provider v-slot="{ errors }" rules="required">
          <vue-editor
            v-model="meeting_with_applicant_info"
            :editor-toolbar="customToolbar"
            :placeholder="$t('form_details.meeting_with_applicant_info')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <div class="d-flex justify-content-center mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="xl"
            @click="updateRequest()"
          >
            <b-spinner v-if="isLoading_meeting_informations" small />
            {{ $t('form_details.check') }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- audit modal -->
    <b-modal
      id="modalAudit"
      ref="modalAudit"
      ok-only
      content-class="shadow"
      :title="$t('form_details.modalAudit')"
      :ok-title="$t('form_details.close_modal')"
      centered
      size="xl"
      no-close-on-backdrop
    >
      <!-- {{ request_have_audit }} -->
      <div
        v-if="auditResult && auditResult.results.length"
        class="d-flex justify-content-end"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
          size="sm"
          @click="onDownloadBilan(request_have_audit.id)"
        >
          <b-spinner v-if="isLoading_download_bilan" small />
          {{ $t('form_details.DownloadBilan') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
          size="sm"
          class="ml-1"
          @click="onDownloadBilanDetailed(request_have_audit.id)"
        >
          <b-spinner v-if="isLoading_download_bilan" small />
          {{ $t('form_details.DownloadBilan') }}
        </b-button>
      </div>
      <div v-if="auditResult && auditResult.results.length" class="px-4 py-3">
        <span>
          <b> {{ $t('form_details.assment') }}</b
          >:
          <span class="text-primary d-inline-block ml-1">{{
            auditResult.title
          }}</span>
        </span>
        <!-- <br /> -->
        <hr />
        <b class="mb-1 d-inline-block">{{
          $t('form_details.elegibility_report')
        }}</b>
        <br />
        <div>
          <div class="d-flex align-items-center" style="gap: 1em">
            <b-button
              v-b-toggle="'collapse-1'"
              size="sm"
              style="width: 100px; margin: 4px 0"
              :variant="
                auditResult.results['1 Etoile'].is_eligible
                  ? 'gradient-success'
                  : 'gradient-danger'
              "
              >{{ $t('form_details.1_star') }}</b-button
            >
            <b
              v-if="auditResult.results['1 Etoile'].is_eligible"
              class="text-success"
              >{{ $t('form_details.eligible') }}</b
            >
            <b
              v-if="!auditResult.results['1 Etoile'].is_eligible"
              class="text-danger"
              >{{ $t('form_details.no_eligible') }}</b
            >
          </div>
          <b-collapse id="collapse-1" visible class="mt-1">
            <ResultsOutput
              :results="auditResult.results"
              :classement-number="String(1)"
            />
          </b-collapse>
        </div>
        <div>
          <div class="d-flex align-items-center" style="gap: 1em">
            <b-button
              v-b-toggle="'collapse-2'"
              size="sm"
              style="width: 100px; margin: 4px 0"
              :variant="
                auditResult.results['2 Etoiles'].is_eligible
                  ? 'gradient-success'
                  : 'gradient-danger'
              "
              >{{ $t('form_details.2_star') }}</b-button
            >
            <b
              v-if="auditResult.results['2 Etoiles'].is_eligible"
              class="text-success"
              >{{ $t('form_details.eligible') }}</b
            >
            <b
              v-if="!auditResult.results['2 Etoiles'].is_eligible"
              class="text-danger"
              >{{ $t('form_details.no_eligible') }}</b
            >
          </div>
          <b-collapse id="collapse-2" visible class="mt-1">
            <ResultsOutput
              :results="auditResult.results"
              :classement-number="String(2)"
            />
          </b-collapse>
        </div>
        <div>
          <div class="d-flex align-items-center" style="gap: 1em">
            <b-button
              v-b-toggle="'collapse-3'"
              size="sm"
              style="width: 100px; margin: 4px 0"
              :variant="
                auditResult.results['3 Etoiles'].is_eligible
                  ? 'gradient-success'
                  : 'gradient-danger'
              "
              >{{ $t('form_details.3_star') }}</b-button
            >
            <b
              v-if="auditResult.results['3 Etoiles'].is_eligible"
              class="text-success"
              >{{ $t('form_details.eligible') }}</b
            >
            <b
              v-if="!auditResult.results['3 Etoiles'].is_eligible"
              class="text-danger"
              >{{ $t('form_details.no_eligible') }}</b
            >
          </div>
          <b-collapse id="collapse-3" visible class="mt-1">
            <ResultsOutput
              :results="auditResult.results"
              :classement-number="String(3)"
            />
          </b-collapse>
        </div>
        <div>
          <div class="d-flex align-items-center" style="gap: 1em">
            <b-button
              v-b-toggle="'collapse-4'"
              size="sm"
              style="width: 100px; margin: 4px 0"
              :variant="
                auditResult.results['4 Etoiles'].is_eligible
                  ? 'gradient-success'
                  : 'gradient-danger'
              "
              >{{ $t('form_details.4_star') }}</b-button
            >
            <b
              v-if="auditResult.results['4 Etoiles'].is_eligible"
              class="text-success"
              >{{ $t('form_details.eligible') }}</b
            >
            <b
              v-if="!auditResult.results['4 Etoiles'].is_eligible"
              class="text-danger"
              >{{ $t('form_details.no_eligible') }}</b
            >
          </div>
          <b-collapse id="collapse-4" visible class="mt-1">
            <ResultsOutput
              :results="auditResult.results"
              :classement-number="String(4)"
            />
          </b-collapse>
        </div>
        <div>
          <div class="d-flex align-items-center" style="gap: 1em">
            <b-button
              v-b-toggle="'collapse-5'"
              size="sm"
              style="width: 100px; margin: 4px 0"
              :variant="
                auditResult.results['5 Etoiles'].is_eligible
                  ? 'gradient-success'
                  : 'gradient-danger'
              "
              >{{ $t('form_details.5_star') }}</b-button
            >
            <b
              v-if="auditResult.results['5 Etoiles'].is_eligible"
              class="text-success"
              >{{ $t('form_details.eligible') }}E</b
            >
            <b
              v-if="!auditResult.results['5 Etoiles'].is_eligible"
              class="text-danger"
              >{{ $t('form_details.no_eligible') }}</b
            >
          </div>
          <b-collapse id="collapse-5" visible class="mt-1">
            <ResultsOutput
              :results="auditResult.results"
              :classement-number="String(5)"
            />
          </b-collapse>
        </div>
        <hr />
      </div>
      <div v-else>
        {{ $t('form_details.audit_info') }}
      </div>
    </b-modal>
    <!-- auto-eval modal -->
    <b-modal
      id="modalAutoEval"
      ref="modalAutoEval"
      ok-only
      content-class="shadow"
      :title="$t('form_details.auto_eval')"
      :ok-title="$t('form_details.close_modal')"
      centered
      size="xl"
      no-close-on-backdrop
      @ok=""
    >
      <!-- {{ request_have_auto_eval }} -->
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
          size="sm"
          @click="onDownloadBilan(request_have_auto_eval.id)"
        >
          <b-spinner v-if="isLoading_download_bilan" small />
          {{ $t('form_details.DownloadBilan') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
          size="sm"
          class="ml-1"
          @click="onDownloadBilanDetailed(request_have_auto_eval.id)"
        >
          <b-spinner v-if="isLoading_download_bilan" small />
          {{ $t('form_details.DownloadBilanDetailed') }}
        </b-button>
      </div>
      <div v-if="evaluationResult" class="px-4 py-3">
        <span
          ><b>{{ $t('form_details.ass') }}</b
          >:
          <span class="text-primary d-inline-block ml-1">{{
            evaluationResult.title
          }}</span></span
        >
        <hr />
        <b class="mb-1 d-inline-block">{{
          $t('form_details.elegibility_report')
        }}</b>
        <br />
        <div>
          <div class="d-flex align-items-center" style="gap: 1em">
            <b-button
              v-b-toggle="'collapse-1'"
              size="sm"
              style="width: 100px; margin: 4px 0"
              :variant="
                evaluationResult.results.is_eligible
                  ? 'gradient-success'
                  : 'gradient-danger'
              "
              >{{
                evaluationResult.results.class && evaluationResult.results.class
              }}
              {{ $t('form_details.star') }}</b-button
            >
            <b
              v-if="evaluationResult.results.is_eligible"
              class="text-success"
              >{{ $t('form_details.eligible') }}</b
            >
            <b
              v-if="!evaluationResult.results.is_eligible"
              class="text-danger"
              >{{ $t('form_details.no_eligible') }}</b
            >
          </div>
          <b-collapse id="collapse-1" visible class="mt-1">
            <ResultsOutput
              :results="evaluationResult.results.data"
              :classement-number="
                String(
                  evaluationResult.results.class &&
                    evaluationResult.results.class
                )
              "
            />
          </b-collapse>
        </div>

        <hr />
      </div>
    </b-modal>
    <b-modal
      id="modalVisit"
      ref="modalVisit"
      ok-only
      content-class="shadow"
      :title="$t('form_details.result')"
      :ok-title="$t('form_details.close_modal')"
      centered
      size="xl"
      no-close-on-backdrop
    >
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
          size="sm"
          @click="downloadPDF('modalVisit')"
        >
          <b-spinner v-if="isDownloading" small />
          {{ $t('form_details.download_pv') }}
        </b-button>
      </div>
      <div class="mx-3">
        <b-row class="my-2 d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-start">
            <AppLogo :width="210" :height="110" />
          </div>
          <div class="d-flex justify-content-end">
            <AppLogo :width="210" :height="110" />
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <strong
            >{{ $t('form_details.current_date') }} {{ currentDate() }}
          </strong>
        </b-row>
        <b-row class="d-flex justify-content-center my-2">
          <h4 class="text-center">
            {{ $t('form_details.accomodation_report') }} «
            {{ pvData && pvData.etablissement }} »
          </h4>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th>{{ $t('form_details.date_time_inspection') }}</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  Le
                  {{ finalPvForm && formatDatepv(finalPvForm.created_at) }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <tr>
                <th colspan="3">
                  {{ $t('form_details.visa') }}
                </th>
              </tr>
              <tr>
                <th colspan="2">
                  {{ $t('form_details.member') }}
                </th>
                <th>Signature</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="finalPvForm && finalPvForm.visas.length != 0">
                <tr
                  v-for="(visitor, indexV) in finalPvForm.visas"
                  :key="indexV"
                  class="ml-2"
                >
                  <td colspan="2">
                    {{ get_user_from_id(visitor.user_id).fullname }}
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="visitor.visa"
                      class="ml-1"
                      switch
                      inline
                      @change="
                        changeSignature(finalPvForm.id, finalPvForm.visas)
                      "
                    >
                      <span v-if="visitor.visa" class="size">{{
                        $t('form_details.sign')
                      }}</span>
                      <span v-else class="size">{{
                        $t('form_details.unsign')
                      }}</span>
                    </b-form-checkbox>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th>{{ $t('form_details.establishment_name') }}</th>
            </thead>
            <tbody>
              <tr>
                <td>{{ pvData && pvData.etablissement }}</td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th colspan="3">
                {{ $t('form_details.contact_details') }}
              </th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>{{ $t('form_details.departement') }}</strong>
                  {{ pvData && pvData.departement }}
                </td>
                <td>
                  <strong>{{ $t('form_details.prefecture') }}</strong>
                </td>
                <td>
                  <strong>{{ $t('form_details.commune') }} </strong>
                  {{ pvData && pvData.commune }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.telephone') }} </strong>
                  {{ pvData && pvData.telephone }}
                </td>
                <td>
                  <strong>Fax :</strong>
                </td>
                <td>
                  <strong>{{ $t('form_details.site') }}</strong>
                  {{ pvData && pvData.site_web }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.opening_date') }}</strong>
                  {{ pvData && pvData.telephone }}
                </td>
                <td>
                  <strong>{{ $t('form_details.nbr_rooms') }}</strong>
                  <b-form-input
                    v-model="pvFormData.coordonnees_etablissement.nbrs_chambres"
                    type="number"
                    class="form-control"
                    placeholder=""
                    @change="updatePvData(finalPvForm.id, pvFormData)"
                  />
                </td>
                <td>
                  <strong>{{ $t('form_details.nbr_beds') }}</strong>
                  <b-form-input
                    v-model="pvFormData.coordonnees_etablissement.nbrs_lits"
                    type="number"
                    class="form-control"
                    placeholder=""
                    @change="updatePvData(finalPvForm.id, pvFormData)"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <strong>Services :</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <b-col>
                    <strong>{{ $t('form_details.service_restaurant') }}</strong>
                    <vue-editor
                      v-model="
                        pvFormData.coordonnees_etablissement.services.restaurant
                      "
                      class=""
                      :editor-toolbar="customToolbar"
                      @input="updatePvData(finalPvForm.id, pvFormData)"
                    />
                  </b-col>
                </td>
                <td>
                  <b-col>
                    <strong>{{ $t('form_details.service_bar') }}</strong>
                    <vue-editor
                      v-model="
                        pvFormData.coordonnees_etablissement.services.bar
                      "
                      class=""
                      :editor-toolbar="customToolbar"
                      @input="updatePvData(finalPvForm.id, pvFormData)"
                    />
                  </b-col>
                </td>
                <td>
                  <b-col>
                    <strong> Spa :</strong>
                    <vue-editor
                      v-model="
                        pvFormData.coordonnees_etablissement.services.spa
                      "
                      class=""
                      :editor-toolbar="customToolbar"
                      @input="updatePvData(finalPvForm.id, pvFormData)"
                    />
                  </b-col>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <strong> {{ $t('form_details.other') }}</strong>
                  <vue-editor
                    v-model="
                      pvFormData.coordonnees_etablissement.services.autres
                    "
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="updatePvData(finalPvForm.id, pvFormData)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th colspan="3">
                {{ $t('form_details.person_legally_responsible') }}
              </th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>{{ $t('form_details.fullname') }} </strong>
                  {{ pvData && pvData.promoteur_fullname }}
                </td>
                <td>
                  <strong>Fonction :</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.telephone') }}</strong
                  >{{ pvData && pvData.telephone }}
                </td>
                <td><strong>E-mail :</strong>{{ pvData && pvData.email }}</td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row
          v-if="finalPvForm && finalPvForm.type == 'classment'"
          class="d-flex justify-content-center"
        >
          <table class="table table-bordered">
            <thead class="head_bg">
              <th>{{ $t('form_details.classification_requested') }}<</th>
            </thead>
            <tbody>
              <tr>
                <td>{{ pvData && pvData.classement }}</td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row
          v-if="finalPvForm && finalPvForm.type == 'classment'"
          class="d-flex justify-content-center"
        >
          <table class="table table-bordered">
            <thead class="head_bg">
              <th>{{ $t('form_details.prev_classification') }}</th>
            </thead>
            <tbody>
              <tr>
                <td>{{ pvData && pvData.classement_prev }}</td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th>
                {{ $t('form_details.main_non_conformities') }}
              </th>
            </thead>
            <tbody>
              <tr v-if="finalPvForm && finalPvForm.type == 'classment'">
                <td>
                  <strong>{{ $t('form_details.non_classement') }}</strong>
                  <vue-editor
                    v-model="pvFormData.evaluation.norme_classement"
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="updatePvData(finalPvForm.id, pvFormData)"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.evaluation_hygiene') }}</strong>
                  <vue-editor
                    v-model="pvFormData.evaluation.hygiene"
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="updatePvData(finalPvForm.id, pvFormData)"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.evaluation_securite') }}</strong>
                  <vue-editor
                    v-model="pvFormData.evaluation.securite"
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="updatePvData(finalPvForm.id, pvFormData)"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.evaluation_autres') }}</strong>
                  <vue-editor
                    v-model="pvFormData.evaluation.autres"
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="updatePvData(finalPvForm.id, pvFormData)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th>{{ $t('form_details.infos_complementaires') }}</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <vue-editor
                    v-model="pvFormData.infos_complementaires"
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="updatePvData(finalPvForm.id, pvFormData)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>

        <b-row>
          <strong class="mb-1">{{ $t('form_details.unite_logement') }} </strong>
          {{ pvData && pvData.unite_logement }}
          <table class="table table-bordered">
            <thead class="head_bg">
              <th>
                {{
                  finalPvForm && finalPvForm.type == 'classment'
                    ? 'Conformité aux normes de classement'
                    : 'Recapitulatif du contrôle'
                }}
              </th>
            </thead>
            <tbody>
              <tr v-if="finalPvForm && finalPvForm.type == 'classment'">
                <td>
                  <strong>{{
                    $t('form_details.proposed_classification')
                  }}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <div
                    v-if="
                      request_technical_visit &&
                      request_technical_visit.length == 0
                    "
                  >
                    <small>{{ $t('form_details.no_result') }} </small>
                  </div>
                  <div v-else>
                    <div v-if="visitResult">
                      <div
                        v-if="
                          visitResult.type == 'audit' ||
                          visitResult.type == 'technical'
                        "
                      >
                        <b-collapse id="collapse-1" visible class="mt-1">
                          <ResultsOutputVisite :results="visitResult.data" />
                        </b-collapse>
                      </div>

                      <div v-if="visitResult.type == 'classment'">
                        <b-collapse id="collapse-1" visible class="mt-1">
                          <ResultsOutput :results="visitResult.data.chapters" />
                        </b-collapse>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th>{{ $t('form_details.improvement') }}</th>
            </thead>
            <tbody>
              <tr v-if="finalPvForm && finalPvForm.type == 'classment'">
                <td>
                  <strong>{{ $t('form_details.norme_classement') }}</strong>
                  <vue-editor
                    v-model="pvFormData.recommandation.norme_classement"
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="updatePvData(finalPvForm.id, pvFormData)"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.evaluation_hygiene') }}</strong>
                  <vue-editor
                    v-model="pvFormData.recommandation.hygiene"
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="updatePvData(finalPvForm.id, pvFormData)"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.evaluation_securite') }}</strong>
                  <vue-editor
                    v-model="pvFormData.recommandation.securite"
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="updatePvData(finalPvForm.id, pvFormData)"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.evaluation_autres') }}</strong>
                  <vue-editor
                    v-model="pvFormData.recommandation.autres"
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="updatePvData(finalPvForm.id, pvFormData)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <tr>
                <th colspan="3">
                  {{ $t('form_details.pic_establishment') }}
                </th>
              </tr>
              <tr>
                <th>Image</th>
                <th>{{ $t('form_details.comment') }}</th>
              </tr>
            </thead>
            <tbody
              v-if="
                finalPvForm &&
                finalPvForm.files &&
                finalPvForm.files.length == 0
              "
            >
              <tr class="text-center">
                <td>{{ $t('form_details.no_image') }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
                v-for="(file, indexF) in finalPvForm && finalPvForm.files"
                :key="indexF"
              >
                <td class="d-flex justify-content-center">
                  <img :src="file.base64" alt="Selected Image" width="200px" />
                </td>
                <td>{{ file.comment }}</td>
              </tr>
            </tbody>
          </table>
        </b-row>
      </div>

      <!-- {{ request_have_auto_eval }} -->
    </b-modal>
    <vue-html2pdf
      v-show="false"
      ref="printablePvVisite"
      class="border rounded"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :manual-pagination="true"
      :filename="`Rapport_de_contrôle_de_etablissement_hébergement/restauration_${
        pvDataGuide && pvDataGuide.etablissement
      }`"
      :pdf-quality="2"
      :pdf-margin="0"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
    >
      <section slot="pdf-content">
        <!-- pv -->
        <section class="pdf-item">
          <div class="mx-3">
            <b-row
              class="my-2 d-flex justify-content-between align-items-center"
            >
              <div class="d-flex justify-content-start">
                <AppLogo :width="210" :height="110" />
              </div>
              <div class="d-flex justify-content-end">
                <AppLogo :width="210" :height="110" />
              </div>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <strong>Cotonou, le {{ currentDate() }} </strong>
            </b-row>
            <b-row class="d-flex justify-content-center my-2">
              <h4 class="text-center">
                {{ $t('form_details.accomodation_report') }} «
                {{ pvData && pvData.etablissement }} »
              </h4>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th>{{ $t('form_details.date_time_inspection') }}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Le
                      {{ finalPvForm && formatDatepv(finalPvForm.created_at) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered table-responsive">
                <thead class="head_bg">
                  <tr>
                    <th colspan="3">
                      {{ $t('form_details.visa') }}
                    </th>
                  </tr>
                  <tr>
                    <th colspan="2">
                      {{ $t('form_details.member') }}
                    </th>
                    <th>Signature</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="ml-2">
                    <td colspan="2">
                      {{
                        finalPvForm &&
                        get_user_from_id(finalPvForm.user.id).fullname
                      }}
                    </td>
                    <td>
                      <span
                        v-if="finalPvForm && finalPvForm.user.signature"
                        class="size text-success"
                        >A signé</span
                      >
                      <span v-else class="size text-red">n'a pas signé</span>
                    </td>
                  </tr>
                  <template
                    v-if="finalPvForm && finalPvForm.visitors.length != 0"
                  >
                    <tr
                      v-for="(visitor, indexV) in finalPvForm.visas"
                      :key="indexV"
                      class="ml-2"
                    >
                      <td colspan="2">
                        {{ get_user_from_id(visitor.user_id).fullname }}
                      </td>
                      <td>
                        <span
                          v-if="finalPvForm && finalPvForm.user.signature"
                          class="size text-success"
                          >A signé</span
                        >
                        <span v-else class="size text-red">{{
                          $t('form_details.unsign')
                        }}</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th>{{ $t('form_details.establishment_name') }}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ pvData && pvData.etablissement }}</td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th colspan="3">
                    {{ $t('form_details.contact_details') }}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>{{
                        $t('form_details.contact_department')
                      }}</strong>
                      {{ pvData && pvData.departement }}
                    </td>
                    <td>
                      <strong>{{ $t('form_details.prefecture') }}</strong>
                    </td>
                    <td>
                      <strong>{{ $t('form_details.commune') }}</strong>
                      {{ pvData && pvData.commune }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{ $t('form_details.telephone') }}</strong>
                      {{ pvData && pvData.telephone }}
                    </td>
                    <td>
                      <strong>Fax :</strong>
                    </td>
                    <td>
                      <strong>{{ $t('form_details.site') }}</strong>
                      {{ pvData && pvData.site_web }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{ $t('form_details.opening_date') }}</strong>
                      {{ pvData && pvData.telephone }}
                    </td>
                    <td>
                      <strong>{{ $t('form_details.nbr_rooms') }}</strong>
                      {{
                        pvFormData.coordonnees_etablissement &&
                        pvFormData.coordonnees_etablissement.nbrs_chambres
                      }}
                    </td>
                    <td>
                      <strong>{{ $t('form_details.nbr_beds') }}</strong>
                      {{
                        pvFormData.coordonnees_etablissement &&
                        pvFormData.coordonnees_etablissement.nbrs_lits
                      }}
                    </td>
                  </tr>
                  <!-- <tr>
                    <td colspan="3">
                      <strong>Services</strong>
                    </td>
                  </tr> -->
                  <tr>
                    <td>
                      <b-col>
                        <strong>{{
                          $t('form_details.service_restaurant')
                        }}</strong>
                        <p
                          v-html="
                            pvFormData.coordonnees_etablissement &&
                            pvFormData.coordonnees_etablissement.services &&
                            pvFormData.coordonnees_etablissement.services
                              .restaurant
                          "
                        />
                      </b-col>
                    </td>
                    <td>
                      <b-col>
                        <strong>{{ $t('form_details.service_bar') }}</strong>
                        <p
                          v-html="
                            pvFormData.coordonnees_etablissement &&
                            pvFormData.coordonnees_etablissement.services &&
                            pvFormData.coordonnees_etablissement.services.bar
                          "
                        />
                      </b-col>
                    </td>
                    <td>
                      <strong> Spa :</strong>
                      <span
                        v-html="
                          pvFormData.coordonnees_etablissement &&
                          pvFormData.coordonnees_etablissement.services &&
                          pvFormData.coordonnees_etablissement.services.spa
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <strong> {{ $t('form_details.other') }}</strong>
                      <p
                        v-html="
                          pvFormData.coordonnees_etablissement &&
                          pvFormData.coordonnees_etablissement.services &&
                          pvFormData.coordonnees_etablissement.services.autres
                        "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <hr />
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th colspan="3">
                    {{ $t('form_details.person_legally_responsible') }}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>{{ $t('form_details.fullname') }}</strong>
                      {{ pvData && pvData.promoteur_fullname }}
                    </td>
                    <td>
                      <strong>Fonction :</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{ $t('form_details.telephone') }}</strong
                      >{{ pvData && pvData.telephone_promoteur }}
                    </td>
                    <td>
                      <strong>E-mail :</strong
                      >{{ pvData && pvData.email_promoteur }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row
              v-if="finalPvForm && finalPvForm.type == 'classment'"
              class="d-flex justify-content-center"
            >
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th>{{ $t('form_details.classification_requested') }}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ pvData && pvData.classement }}</td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row
              v-if="finalPvForm && finalPvForm.type == 'classment'"
              class="d-flex justify-content-center"
            >
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th>{{ $t('form_details.contact_details') }}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ pvData && pvData.classement_prev }}</td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th>
                    {{ $t('form_details.main_non_conformities') }}
                  </th>
                </thead>
                <tbody>
                  <tr v-if="finalPvForm && finalPvForm.type == 'classment'">
                    <td>
                      <strong>{{ $t('form_details.norme_classement') }}</strong>
                      <p
                        v-html="
                          pvFormData.evaluation &&
                          pvFormData.evaluation.norme_classement
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{
                        $t('form_details.evamuation_hygiene')
                      }}</strong>
                      <p
                        v-html="
                          pvFormData.evaluation && pvFormData.evaluation.hygiene
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{
                        $t('form_details.evaluation_securite')
                      }}</strong>
                      <p
                        v-html="
                          pvFormData.evaluation &&
                          pvFormData.evaluation.securite
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{
                        $t('form_details.evaluation_autres')
                      }}</strong>
                      <p
                        v-html="
                          pvFormData.evaluation && pvFormData.evaluation.autres
                        "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th>{{ $t('form_details.infos_complementaires') }}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p
                        v-html="pvFormData && pvFormData.infos_complementaires"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>

            <b-row>
              <strong class="mb-1"
                >{{ $t('form_details.unite_logement') }}
              </strong>
              {{ pvData && pvData.unite_logement }}
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th>
                    {{
                      finalPvForm && finalPvForm.type == 'classment'
                        ? 'Conformité aux normes de classement'
                        : 'Recapitulatif du contrôle'
                    }}
                  </th>
                </thead>
                <tbody>
                  <tr v-if="finalPvForm && finalPvForm.type == 'classment'">
                    <td>
                      <strong>{{
                        $t('form_details.proposed_classification')
                      }}</strong>
                      {{ pvData && pvData.classement_propose }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        v-if="
                          request_technical_visit &&
                          request_technical_visit.length == 0
                        "
                      >
                        <small>{{ $t('form_details.no_result') }} </small>
                      </div>
                      <div v-else>
                        <div v-if="visitResult">
                          <div
                            v-if="
                              visitResult.type == 'audit' ||
                              visitResult.type == 'technical'
                            "
                          >
                            <b-collapse id="collapse-1" visible class="mt-1">
                              <ResultsOutputVisite
                                :results="visitResult.data"
                              />
                            </b-collapse>
                          </div>

                          <div v-if="visitResult.type == 'classment'">
                            <b-collapse id="collapse-1" visible class="mt-1">
                              <ResultsOutput
                                :results="visitResult.data.chapters"
                              />
                            </b-collapse>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th>{{ $t('form_details.improvement') }}</th>
                </thead>
                <tbody>
                  <tr v-if="finalPvForm && finalPvForm.type == 'classment'">
                    <td>
                      <strong>{{ $t('form_details.norme_classement') }}</strong>
                      <p
                        v-html="
                          pvFormData.recommandation &&
                          pvFormData.recommandation.norme_classement
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{
                        $t('form_details.evaluation_hygiene')
                      }}</strong>
                      <p
                        v-html="
                          pvFormData.recommandation &&
                          pvFormData.recommandation.hygiene
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{
                        $t('form_details.evaluation_securite')
                      }}</strong>
                      <p
                        v-html="
                          pvFormData.recommandation &&
                          pvFormData.recommandation.securite
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{
                        $t('form_details.evaluation_autres')
                      }}</strong>
                      <p
                        v-html="
                          pvFormData.recommandation &&
                          pvFormData.recommandation.autres
                        "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <tr>
                    <th colspan="3">
                      {{ $t('form_details.pic_establishment') }}
                    </th>
                  </tr>
                  <tr>
                    <th>Image</th>
                    <th>{{ $t('form_details.comment') }}</th>
                  </tr>
                </thead>
                <tbody
                  v-if="
                    finalPvForm &&
                    finalPvForm.files &&
                    finalPvForm.files.length == 0
                  "
                >
                  <tr class="text-center">
                    <td>{{ $t('form_details.no_image') }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr
                    v-for="(file, indexF) in finalPvForm && finalPvForm.files"
                    :key="indexF"
                  >
                    <td class="d-flex justify-content-center">
                      <img
                        :src="file.base64"
                        alt="Selected Image"
                        width="200px"
                      />
                    </td>
                    <td>{{ file.comment }}</td>
                  </tr>
                </tbody>
              </table>
            </b-row>
          </div>
        </section>

        <!-- <hr class="invoice-spacing" /> -->
      </section>
    </vue-html2pdf>
    <b-modal
      id="modalVisitGuide"
      ref="modalVisitGuide"
      ok-only
      content-class="shadow"
      title="Procès verbal associé à la visite"
      ok-title="Fermer"
      centered
      size="xl"
      no-close-on-backdrop
    >
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
          size="sm"
          @click="downloadPDF('modalVisitGuide')"
        >
          <b-spinner v-if="isDownloading" small />
          Télécharger le PV
        </b-button>
      </div>
      <div class="mx-3">
        <b-row class="my-2 d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-start">
            <AppLogo :width="210" :height="110" />
          </div>
          <div class="d-flex justify-content-end">
            <AppLogo :width="210" :height="110" />
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <strong
            >{{ $t('form_details.current_date') }} {{ currentDate() }}
          </strong>
        </b-row>
        <b-row class="d-flex justify-content-center my-2">
          <h4 class="text-center">
            {{ $t('form_details.meet_report') }}<br />«
            {{ pvDataGuide && pvDataGuide.etablissement }} »
          </h4>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th>{{ $t('form_details.date_time_inspection') }}</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  Le
                  <!-- {{ finalPvForm && formatDatepv(finalPvForm.created_at) }} -->
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <!-- <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <tr>
                <th colspan="3">Visa des membres</th>
              </tr>
              <tr>
                <th colspan="2">Membre</th>
                <th>Signature</th>
              </tr>
            </thead>
            <tbody>
              <tr class="ml-2">
                <td colspan="2">
                  {{
                    finalPvForm &&
                    get_user_from_id(finalPvForm.user.id).fullname
                  }}
                </td>
                <td>
                  <b-form-checkbox
                    class="ml-1"
                    switch
                    inline
                    v-model="finalPvForm && finalPvForm.user.signature"
                  >
                    <span
                      class="size"
                      v-if="finalPvForm && finalPvForm.user.signature"
                      >A signé</span
                    >
                    <span class="size" v-else>n'a pas signé</span>
                  </b-form-checkbox>
                </td>
              </tr>
              <template v-if="finalPvForm && finalPvForm.visitors.length != 0">
                <tr
                  class="ml-2"
                  v-for="(visitor, indexV) in finalPvForm.visitors"
                  :key="indexV"
                >
                  <td colspan="2">
                    {{ get_user_from_id(visitor.id).fullname }}
                  </td>
                  <td>
                    <b-form-checkbox
                      class="ml-1"
                      switch
                      inline
                      v-model="visitor.signature"
                    >
                      <span class="size" v-if="visitor.signature">A signé</span>
                      <span class="size" v-else>n'a pas signé</span>
                    </b-form-checkbox>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </b-row> -->
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th>
                {{ $t('form_details.socila_report') }}
              </th>
            </thead>
            <tbody>
              <tr>
                <td>{{ pvDataGuide && pvDataGuide.etablissement }}</td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th colspan="3">{{ $t('form_details.contact_details') }}</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>{{ $t('form_details.departement') }}</strong>
                  {{ pvDataGuide && pvDataGuide.departement }}
                </td>
                <td>
                  <strong>{{ $t('form_details.prefecture') }}</strong>
                </td>
                <td>
                  <strong>{{ $t('form_details.commune') }}</strong>
                  {{ pvDataGuide && pvDataGuide.commune }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.telephone') }} </strong>
                  {{ pvDataGuide && pvDataGuide.telephone }}
                </td>
                <td>
                  <strong>Fax :</strong>
                </td>
                <td>
                  <strong>{{ $t('form_details.site') }}</strong>
                  {{ pvDataGuide && pvDataGuide.site_web }}
                </td>
              </tr>

              <tr>
                <td>
                  <b-col>
                    <strong>{{ $t('form_details.category') }}</strong>
                    <vue-editor
                      v-model="
                        pvFormDataGuide.coordonnees_etablissement
                          .categorie_demandee
                      "
                      class=""
                      :editor-toolbar="customToolbar"
                    />
                  </b-col>
                </td>
                <td>
                  <b-col>
                    <strong>{{ $t('form_details.type') }}</strong>
                    <vue-editor
                      v-model="
                        pvFormDataGuide.coordonnees_etablissement.typologie
                      "
                      class=""
                      :editor-toolbar="customToolbar"
                    />
                  </b-col>
                </td>
                <td>
                  <b-col>
                    <strong>{{ $t('form_details.location') }}</strong>
                    <vue-editor
                      v-model="
                        pvFormDataGuide.coordonnees_etablissement.localite
                      "
                      class=""
                      :editor-toolbar="customToolbar"
                    />
                  </b-col>
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th colspan="3">
                {{ $t('form_details.person_legally_responsible') }}
              </th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>{{ $t('form_details.fullname') }}</strong>
                  {{ pvDataGuide && pvDataGuide.promoteur_fullname }}
                </td>
                <td>
                  <strong>Profession :</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.telephone') }}</strong
                  >{{ pvDataGuide && pvDataGuide.telephone }}
                </td>
                <td>
                  <strong>E-mail :</strong
                  >{{ pvDataGuide && pvDataGuide.email }}
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table table-bordered">
            <thead class="head_bg">
              <th colspan="3">{{ $t('form_details.guide_contact') }}</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>{{ $t('form_details.fullname') }} </strong>
                  {{ pvDataGuide && pvDataGuide.promoteur_fullname }}
                </td>
                <td>
                  <strong>Profession :</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.telephone') }}</strong
                  >{{ pvDataGuide && pvDataGuide.telephone }}
                </td>
                <td>
                  <strong>E-mail :</strong
                  >{{ pvDataGuide && pvDataGuide.email }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>

        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th>{{ $t('form_details.main_findings') }}</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span class="d-flex align-items-center">
                    <strong>{{ $t('form_details.evaluation_plan1') }}</strong
                    ><b-form-input
                      v-model="pvFormDataGuide.evaluation.plan1"
                      type="text"
                      class="form-control w-25 ml-1 mb-1"
                      placeholder=""
                    />
                  </span>
                  <vue-editor
                    v-model="pvFormDataGuide.evaluation.plan1Details"
                    class=""
                    :editor-toolbar="customToolbar"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <span class="d-flex align-items-center">
                    <strong>{{ $t('form_details.evaluation_plan2') }}</strong
                    ><b-form-input
                      v-model="pvFormDataGuide.evaluation.plan2"
                      type="text"
                      class="form-control w-25 ml-1 mb-1"
                      placeholder=""
                    />
                  </span>

                  <vue-editor
                    v-model="pvFormDataGuide.evaluation.plan2Details"
                    class=""
                    :editor-toolbar="customToolbar"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <span class="d-flex align-items-center">
                    <strong>{{ $t('form_details.evaluation_plan3') }}</strong>
                    <b-form-input
                      v-model="pvFormDataGuide.evaluation.plan3"
                      type="text"
                      class="form-control w-25 ml-1 mb-1"
                      placeholder=""
                    />
                  </span>

                  <vue-editor
                    v-model="pvFormDataGuide.evaluation.plan3Details"
                    class=""
                    :editor-toolbar="customToolbar"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <span class="d-flex align-items-center">
                    <strong>{{ $t('form_details.evaluation_plan4') }}</strong
                    ><b-form-input
                      v-model="pvFormDataGuide.evaluation.plan4"
                      type="text"
                      class="form-control w-25 ml-1 mb-1"
                      placeholder=""
                    />
                  </span>

                  <vue-editor
                    v-model="pvFormDataGuide.evaluation.plan4Details"
                    class=""
                    :editor-toolbar="customToolbar"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th>{{ $t('form_details.improvement') }}</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong
                    >{{ $t('form_details.evaluation_plan1') }}
                    {{ pvFormDataGuide.evaluation.plan1 }} :</strong
                  >
                  <vue-editor
                    v-model="pvFormDataGuide.recommandation.plan1Details"
                    class=""
                    :editor-toolbar="customToolbar"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong
                    >{{ $t('form_details.evaluation_plan2') }}
                    {{
                      pvFormDataGuide &&
                      pvFormDataGuide.evaluation &&
                      pvFormDataGuide.evaluation.plan2
                    }}
                    :</strong
                  >
                  <vue-editor
                    v-model="pvFormDataGuide.recommandation.plan2Details"
                    class=""
                    :editor-toolbar="customToolbar"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong
                    >{{ $t('form_details.evaluation_plan3') }}
                    {{
                      pvFormDataGuide &&
                      pvFormDataGuide.evaluation &&
                      pvFormDataGuide.evaluation.plan3
                    }}
                    :</strong
                  >
                  <vue-editor
                    v-model="pvFormDataGuide.recommandation.plan3Details"
                    class=""
                    :editor-toolbar="customToolbar"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong
                    >{{ $t('form_details.evaluation_plan4') }}
                    {{
                      pvFormDataGuide &&
                      pvFormDataGuide.evaluation &&
                      pvFormDataGuide.evaluation.plan4
                    }}
                    :</strong
                  >
                  <vue-editor
                    v-model="pvFormDataGuide.recommandation.plan4Details"
                    class=""
                    :editor-toolbar="customToolbar"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>
      </div>
      <!-- {{ request_have_auto_eval }} -->
    </b-modal>
    <vue-html2pdf
      v-show="false"
      ref="printablePvGuide"
      class="border rounded"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :manual-pagination="true"
      :filename="`Rapport_d’entretien_avec_le_Guide_${
        pvDataGuide && pvDataGuide.etablissement
      }`"
      :pdf-quality="2"
      :pdf-margin="0"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
    >
      <section slot="pdf-content">
        <!-- pv -->
        <section class="pdf-item">
          <div class="mx-3">
            <b-row
              class="my-2 d-flex justify-content-between align-items-center"
            >
              <div class="d-flex justify-content-start">
                <AppLogo :width="210" :height="110" />
              </div>
              <div class="d-flex justify-content-end">
                <AppLogo :width="210" :height="110" />
              </div>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <strong
                >{{ $t('form_details.current_date') }}{{ currentDate() }}
              </strong>
            </b-row>
            <b-row class="d-flex justify-content-center my-2">
              <h4 class="text-center">
                {{ $t('form_details.guide_report') }}<br />«
                {{ pvDataGuide && pvDataGuide.etablissement }} »
              </h4>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th>{{ $t('form_details.date_time_inspection') }}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Le
                      <!-- {{ finalPvForm && formatDatepv(finalPvForm.created_at) }} -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <!-- <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <tr>
                <th colspan="3">Visa des membres</th>
              </tr>
              <tr>
                <th colspan="2">Membre</th>
                <th>Signature</th>
              </tr>
            </thead>
            <tbody>
              <tr class="ml-2">
                <td colspan="2">
                  {{
                    finalPvForm &&
                    get_user_from_id(finalPvForm.user.id).fullname
                  }}
                </td>
                <td>
                  <b-form-checkbox
                    class="ml-1"
                    switch
                    inline
                    v-model="finalPvForm && finalPvForm.user.signature"
                  >
                    <span
                      class="size"
                      v-if="finalPvForm && finalPvForm.user.signature"
                      >A signé</span
                    >
                    <span class="size" v-else>n'a pas signé</span>
                  </b-form-checkbox>
                </td>
              </tr>
              <template v-if="finalPvForm && finalPvForm.visitors.length != 0">
                <tr
                  class="ml-2"
                  v-for="(visitor, indexV) in finalPvForm.visitors"
                  :key="indexV"
                >
                  <td colspan="2">
                    {{ get_user_from_id(visitor.id).fullname }}
                  </td>
                  <td>
                    <b-form-checkbox
                      class="ml-1"
                      switch
                      inline
                      v-model="visitor.signature"
                    >
                      <span class="size" v-if="visitor.signature">A signé</span>
                      <span class="size" v-else>n'a pas signé</span>
                    </b-form-checkbox>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </b-row> -->
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th>
                    {{ $t('form_details.social_report') }}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ pvDataGuide && pvDataGuide.etablissement }}</td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th colspan="3">{{ $t('form_details.contact_details') }}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>{{ $t('form_details.departement') }}</strong>
                      {{ pvDataGuide && pvDataGuide.departement }}
                    </td>
                    <td>
                      <strong>{{ $t('form_details.prefecture') }}</strong>
                    </td>
                    <td>
                      <strong>{{ $t('form_details.commune') }} </strong>
                      {{ pvDataGuide && pvDataGuide.commune }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{ $t('form_details.telephone') }} </strong>
                      {{ pvDataGuide && pvDataGuide.telephone }}
                    </td>
                    <td>
                      <strong>Fax :</strong>
                    </td>
                    <td>
                      <strong>{{ $t('form_details.site') }}</strong>
                      {{ pvDataGuide && pvDataGuide.site_web }}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <b-col>
                        <strong>{{ $t('form_details.category') }}</strong>
                        <p
                          v-html="
                            pvFormDataGuide &&
                            pvFormDataGuide.coordonnees_etablissement &&
                            pvFormDataGuide.coordonnees_etablissement
                              .categorie_demandee
                          "
                        />
                      </b-col>
                    </td>
                    <td>
                      <b-col>
                        <strong>{{ $t('form_details.type') }}</strong>
                        <p
                          v-html="
                            pvFormDataGuide &&
                            pvFormDataGuide.coordonnees_etablissement &&
                            pvFormDataGuide.coordonnees_etablissement.typologie
                          "
                        />
                      </b-col>
                    </td>
                    <td>
                      <b-col>
                        <strong>{{ $t('form_details.location') }}</strong>
                        <p
                          v-html="
                            pvFormDataGuide &&
                            pvFormDataGuide.coordonnees_etablissement &&
                            pvFormDataGuide.coordonnees_etablissement.localite
                          "
                        />
                      </b-col>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th colspan="3">
                    {{ $t('form_details.person_legally_responsible') }}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>{{ $t('form_details.fullname') }}</strong>
                      {{ pvDataGuide && pvDataGuide.promoteur_fullname }}
                    </td>
                    <td>
                      <strong>Profession :</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{ $t('form_details.telephone') }}</strong
                      >{{ pvDataGuide && pvDataGuide.telephone }}
                    </td>
                    <td>
                      <strong>E-mail :</strong
                      >{{ pvDataGuide && pvDataGuide.email }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th colspan="3">{{ $t('form_details.guide_contact') }}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>{{ $t('form_details.fullname') }}</strong>
                      {{ pvDataGuide && pvDataGuide.promoteur_fullname }}
                    </td>
                    <td>
                      <strong>Profession :</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{ $t('form_details.telephone') }}</strong
                      >{{ pvDataGuide && pvDataGuide.telephone }}
                    </td>
                    <td>
                      <strong>E-mail :</strong
                      >{{ pvDataGuide && pvDataGuide.email }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>

            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th>{{ $t('form_details.main_findings') }}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong
                        >{{ $t('form_details.evaluation_plan1') }}
                        {{
                          pvFormDataGuide &&
                          pvFormDataGuide.evaluation &&
                          pvFormDataGuide.evaluation.plan1
                        }}
                        :</strong
                      >
                      <p
                        v-html="
                          pvFormDataGuide &&
                          pvFormDataGuide.evaluation &&
                          pvFormDataGuide.evaluation.plan1Details
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong
                        >{{ $t('form_details.evaluation_plan2') }}
                        {{
                          pvFormDataGuide &&
                          pvFormDataGuide.evaluation &&
                          pvFormDataGuide.evaluation.plan2
                        }}
                        :</strong
                      >
                      <p
                        v-html="
                          pvFormDataGuide &&
                          pvFormDataGuide.evaluation &&
                          pvFormDataGuide.evaluation.plan2Details
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong
                        >{{ $t('form_details.evaluation_plan3') }}
                        {{
                          pvFormDataGuide &&
                          pvFormDataGuide.evaluation &&
                          pvFormDataGuide.evaluation.plan3
                        }}
                        :</strong
                      >
                      <p
                        v-html="
                          pvFormDataGuide &&
                          pvFormDataGuide.evaluation &&
                          pvFormDataGuide.evaluation.plan3Details
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong
                        >{{ $t('form_details.evaluation_plan4') }}
                        {{
                          pvFormDataGuide &&
                          pvFormDataGuide.evaluation &&
                          pvFormDataGuide.evaluation.plan4
                        }}
                        :</strong
                      >
                      <p
                        v-html="
                          pvFormDataGuide &&
                          pvFormDataGuide.evaluation &&
                          pvFormDataGuide.evaluation.plan4Details
                        "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th>{{ $t('form_details.improvement') }}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong
                        >{{ $t('form_details.evaluation_plan1') }}
                        {{
                          pvFormDataGuide &&
                          pvFormDataGuide.evaluation &&
                          pvFormDataGuide.evaluation.plan1
                        }}
                        :</strong
                      >
                      <span
                        v-html="
                          pvFormDataGuide &&
                          pvFormDataGuide.recommandation &&
                          pvFormDataGuide.recommandation.plan1Details
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong
                        >{{ $t('form_details.evaluation_plan2') }}
                        {{
                          pvFormDataGuide &&
                          pvFormDataGuide.evaluation &&
                          pvFormDataGuide.evaluation.plan2
                        }}
                        :</strong
                      >
                      <span
                        v-html="
                          pvFormDataGuide &&
                          pvFormDataGuide.recommandation &&
                          pvFormDataGuide.recommandation.plan2Details
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong
                        >{{ $t('form_details.evaluation_plan3') }}
                        {{
                          pvFormDataGuide &&
                          pvFormDataGuide.evaluation &&
                          pvFormDataGuide.evaluation.plan3
                        }}
                        :</strong
                      >
                      <span
                        v-html="
                          pvFormDataGuide &&
                          pvFormDataGuide.recommandation &&
                          pvFormDataGuide.recommandation.plan3Details
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong
                        >{{ $t('form_details.evaluation_plan4') }}
                        {{
                          pvFormDataGuide &&
                          pvFormDataGuide.evaluation &&
                          pvFormDataGuide.evaluation.plan4
                        }}
                        :</strong
                      >
                      <p
                        v-html="
                          pvFormDataGuide &&
                          pvFormDataGuide.recommandation &&
                          pvFormDataGuide.recommandation.plan4Details
                        "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
          </div>
        </section>

        <!-- <hr class="invoice-spacing" /> -->
      </section>
    </vue-html2pdf>

    <b-modal
      id="modalVisitAgenceVoyage"
      ref="modalVisitAgenceVoyage"
      ok-only
      content-class="shadow"
      :title="$t('form_details.agence_report_title')"
      :ok-title="$t('form_details.close_modal')"
      centered
      size="xl"
      no-close-on-backdrop
    >
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
          size="sm"
          @click="downloadPDF('modalVisitAgenceVoyage')"
        >
          <b-spinner v-if="isDownloading" small />
          {{ $t('form_details.download_pv') }}
        </b-button>
      </div>
      <div class="mx-3">
        <b-row class="my-2 d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-start">
            <AppLogo :width="210" :height="110" />
          </div>
          <div class="d-flex justify-content-end">
            <AppLogo :width="210" :height="110" />
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <strong
            >{{ $t('form_details.current_date') }} {{ currentDate() }}
          </strong>
        </b-row>
        <b-row class="d-flex justify-content-center my-2">
          <h4 class="text-center">
            {{ $t('form_details.agence_report_title') }} «
            {{ pvData && pvData.etablissement }} »
          </h4>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th>{{ $t('form_details.date_time_inspection') }}</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  Le
                  {{ finalPvForm && formatDatepv(finalPvForm.created_at) }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <tr>
                <th colspan="3">
                  {{ $t('form_details.visa') }}
                </th>
              </tr>
              <tr>
                <th colspan="2">
                  {{ $t('form_details.member') }}
                </th>
                <th>Signature</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="finalPvForm && finalPvForm.visas.length != 0">
                <tr
                  v-for="(visitor, indexV) in finalPvForm.visas"
                  :key="indexV"
                  class="ml-2"
                >
                  <td colspan="2">
                    {{ get_user_from_id(visitor.user_id).fullname }}
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="visitor.visa"
                      class="ml-1"
                      switch
                      inline
                      @change="
                        changeSignature(finalPvForm.id, finalPvForm.visas)
                      "
                    >
                      <span v-if="visitor.visa" class="size">{{
                        $t('form_details.sign')
                      }}</span>
                      <span v-else class="size">{{
                        $t('form_details.unsign')
                      }}</span>
                    </b-form-checkbox>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th>{{ $t('form_details.establishment_name') }}</th>
            </thead>
            <tbody>
              <tr>
                <td>{{ pvData && pvData.etablissement }}</td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th colspan="3">
                {{ $t('form_details.contact_details') }}
              </th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>>{{ $t('form_details.departement') }}</strong>
                  {{ pvData && pvData.departement }}
                </td>
                <td>
                  <strong>{{ $t('form_details.prefecture') }}</strong>
                </td>
                <td>
                  <strong>{{ $t('form_details.commune') }} </strong>
                  {{ pvData && pvData.commune }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.telephone') }} </strong>
                  {{ pvData && pvData.telephone }}
                </td>
                <td>
                  <strong>Fax :</strong>
                </td>
                <td>
                  <strong>{{ $t('form_details.site') }}</strong>
                  {{ pvData && pvData.site_web }}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <strong>Services :</strong>
                  <vue-editor
                    v-model="pvFormDataAV.coordonnees_etablissement.services"
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="updatePvData(finalPvForm.id, pvFormDataAV)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th colspan="3">
                {{ $t('form_details.person_legally_responsible') }}
              </th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>{{ $t('form_details.fullname') }} </strong>
                  {{ pvData && pvData.promoteur_fullname }}
                </td>
                <td>
                  <strong>Profession :</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.telephone') }}</strong>
                  {{ pvData && pvData.telephone }}
                </td>
                <td><strong>E-mail :</strong>{{ pvData && pvData.email }}</td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th colspan="3">
                {{ $t('form_details.gerant_contact') }}
              </th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>{{ $t('form_details.fullname') }} </strong>
                  {{ pvData && pvData.promoteur_fullname }}
                </td>
                <td>
                  <strong>Profession :</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.telephone') }}</strong>
                  {{ pvData && pvData.telephone }}
                </td>
                <td><strong>E-mail :</strong>{{ pvData && pvData.email }}</td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th>
                {{ $t('form_details.main_non_conformities') }}
              </th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span class="d-flex align-items-center">
                    <strong>{{ $t('form_details.agence_plan') }}</strong
                    ><b-form-input
                      v-model="pvFormDataAV.evaluation.plan"
                      type="text"
                      class="form-control w-25 ml-1 mb-1"
                      placeholder=""
                      @change="updatePvData(finalPvForm.id, pvFormDataAV)"
                    />
                  </span>
                  <vue-editor
                    v-model="pvFormDataAV.evaluation.norme"
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="
                      updatePvData(finalPvForm.id, pvFormDataAV && pvFormDataAV)
                    "
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.evaluation_hygiene') }}</strong>
                  <vue-editor
                    v-model="pvFormDataAV.evaluation.hygiene"
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="
                      updatePvData(finalPvForm.id, pvFormDataAV && pvFormDataAV)
                    "
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.evaluation_securite') }}</strong>
                  <vue-editor
                    v-model="pvFormDataAV.evaluation.securite"
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="
                      updatePvData(finalPvForm.id, pvFormDataAV && pvFormDataAV)
                    "
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.evaluation_autres') }}</strong>
                  <vue-editor
                    v-model="pvFormDataAV.evaluation.autres"
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="
                      updatePvData(finalPvForm.id, pvFormDataAV && pvFormDataAV)
                    "
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>

        <b-row>
          <table class="table table-bordered">
            <thead class="head_bg">
              <th>
                {{
                  finalPvForm && finalPvForm.type == 'classment'
                    ? 'Conformité aux normes de classement'
                    : 'Recapitulatif du contrôle'
                }}
              </th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div v-if="finalPvForm && finalPvForm.data == null">
                    <small>{{ $t('form_details.no_results') }} </small>
                  </div>
                  <div v-else>
                    <div v-if="finalPvForm">
                      <div
                        v-if="
                          finalPvForm.type == 'audit' ||
                          finalPvForm.type == 'technical'
                        "
                      >
                        <b-collapse id="collapse-1" visible class="mt-1">
                          <ResultsOutputVisite :results="finalPvForm.data" />
                        </b-collapse>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <th>{{ $t('form_details.improvement') }}</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong
                    >{{ $t('form_details.agence_plan')
                    }}{{
                      pvFormDataAV &&
                      pvFormDataAV.evaluation &&
                      pvFormDataAV.evaluation.plan
                    }}
                    :</strong
                  >
                  <vue-editor
                    v-model="pvFormDataAV.recommandation.norme"
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="
                      updatePvData(finalPvForm.id, pvFormDataAV && pvFormDataAV)
                    "
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.evaluation_hygiene') }}</strong>
                  <vue-editor
                    v-model="pvFormDataAV.recommandation.hygiene"
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="
                      updatePvData(finalPvForm.id, pvFormDataAV && pvFormDataAV)
                    "
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.evaluation_securite') }}</strong>
                  <vue-editor
                    v-model="pvFormDataAV.recommandation.securite"
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="
                      updatePvData(finalPvForm.id, pvFormDataAV && pvFormDataAV)
                    "
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{ $t('form_details.evaluation_autres') }}</strong>
                  <vue-editor
                    v-model="pvFormDataAV.recommandation.autres"
                    class=""
                    :editor-toolbar="customToolbar"
                    @input="
                      updatePvData(finalPvForm.id, pvFormDataAV && pvFormDataAV)
                    "
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <table class="table table-bordered">
            <thead class="head_bg">
              <tr>
                <th colspan="3">
                  {{ $t('form_details.pic_establishment') }}
                </th>
              </tr>
              <tr>
                <th>Image</th>
                <th>{{ $t('form_details.comment') }}</th>
              </tr>
            </thead>
            <tbody
              v-if="
                finalPvForm &&
                finalPvForm.files &&
                finalPvForm.files.length == 0
              "
            >
              <tr class="text-center">
                <td colspan="2">
                  {{ $t('form_details.no_image') }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
                v-for="(file, indexF) in finalPvForm && finalPvForm.files"
                :key="indexF"
              >
                <td class="d-flex justify-content-center">
                  <img :src="file.base64" alt="Selected Image" width="200px" />
                </td>
                <td>{{ file.comment }}</td>
              </tr>
            </tbody>
          </table>
        </b-row>
      </div>

      <!-- {{ request_have_auto_eval }} -->
    </b-modal>
    <vue-html2pdf
      v-show="false"
      ref="printablePvAgenceVoyage"
      class="border rounded"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :manual-pagination="true"
      :filename="`Rapport_de_contrôle_de_agence_voyage_${
        pvData && pvData.etablissement
      }`"
      :pdf-quality="2"
      :pdf-margin="0"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
    >
      <section slot="pdf-content">
        <!-- pv -->
        <section class="pdf-item">
          <div class="mx-3">
            <b-row
              class="my-2 d-flex justify-content-between align-items-center"
            >
              <div class="d-flex justify-content-start">
                <AppLogo :width="210" :height="110" />
              </div>
              <div class="d-flex justify-content-end">
                <AppLogo :width="210" :height="110" />
              </div>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <strong>Cotonou, le {{ currentDate() }} </strong>
            </b-row>
            <b-row class="d-flex justify-content-center my-2">
              <h4 class="text-center">
                {{ $t('form_details.agence_report_title') }} «
                {{ pvData && pvData.etablissement }} »
              </h4>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th>{{ $t('form_details.date_time_inspection') }}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Le
                      {{ finalPvForm && formatDatepv(finalPvForm.created_at) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered table-responsive">
                <thead class="head_bg">
                  <tr>
                    <th colspan="3">
                      {{ $t('form_details.visa') }}
                    </th>
                  </tr>
                  <tr>
                    <th colspan="2">
                      {{ $t('form_details.member') }}
                    </th>
                    <th>Signature</th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-if="finalPvForm && finalPvForm.visitors.length != 0"
                  >
                    <tr
                      v-for="(visitor, indexV) in finalPvForm.visas"
                      :key="indexV"
                      class="ml-2"
                    >
                      <td colspan="2">
                        {{ get_user_from_id(visitor.user_id).fullname }}
                      </td>
                      <td>
                        <span v-if="visitor.visa" class="size text-success">{{
                          $t('form_details.sign')
                        }}</span>
                        <span v-else class="size text-red">{{
                          $t('form_details.unsign')
                        }}</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th>{{ $t('form_details.establishment_name') }}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ pvData && pvData.etablissement }}</td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th colspan="3">
                    {{ $t('form_details.contact_details') }}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>{{
                        $t('form_details.contact_department')
                      }}</strong>
                      {{ pvData && pvData.departement }}
                    </td>
                    <td>
                      <strong>{{ $t('form_details.prefecture') }}</strong>
                    </td>
                    <td>
                      <strong>{{ $t('form_details.commune') }}</strong>
                      {{ pvData && pvData.commune }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{ $t('form_details.telephone') }}</strong>
                      {{ pvData && pvData.telephone }}
                    </td>
                    <td>
                      <strong>Fax :</strong>
                    </td>
                    <td>
                      <strong>{{ $t('form_details.site') }}</strong>
                      {{ pvData && pvData.site_web }}
                    </td>
                  </tr>

                  <!-- <tr>
                    <td colspan="3">
                      <strong>Services</strong>
                    </td>
                  </tr> -->
                  <tr>
                    <td colspan="3">
                      >
                      <b-col>
                        <strong>Services :</strong>
                        <p
                          v-html="
                            pvFormDataAV &&
                            pvFormDataAV.coordonnees_etablissement &&
                            pvFormDataAV.coordonnees_etablissement.services
                          "
                        />
                      </b-col>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>

            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th colspan="3">
                    {{ $t('form_details.person_legally_responsible') }}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>{{ $t('form_details.fullname') }}</strong>
                      {{ pvData && pvData.promoteur_fullname }}
                    </td>
                    <td>
                      <strong>Profession :</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{ $t('form_details.telephone') }}</strong
                      >{{ pvData && pvData.telephone_promoteur }}
                    </td>
                    <td>
                      <strong>E-mail :</strong
                      >{{ pvData && pvData.email_promoteur }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th colspan="3">
                    {{ $t('form_details.person_legally_responsible') }}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>{{ $t('form_details.fullname') }}</strong>
                      {{ pvData && pvData.promoteur_fullname }}
                    </td>
                    <td>
                      <strong>Profession :</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{ $t('form_details.telephone') }}</strong
                      >{{ pvData && pvData.telephone_promoteur }}
                    </td>
                    <td>
                      <strong>E-mail :</strong
                      >{{ pvData && pvData.email_promoteur }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>

            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th>
                    {{ $t('form_details.main_non_conformities') }}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong
                        >{{ $t('form_details.agence_plan')
                        }}{{
                          pvFormDataAV &&
                          pvFormDataAV.evaluation &&
                          pvFormDataAV.evaluation.plan
                        }}
                        :</strong
                      >
                      <p
                        v-html="
                          pvFormDataAV &&
                          pvFormDataAV.evaluation &&
                          pvFormDataAV.evaluation.norme
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{
                        $t('form_details.evaluation_hygiene')
                      }}</strong>
                      <p
                        v-html="
                          pvFormDataAV &&
                          pvFormDataAV.evaluation &&
                          pvFormDataAV.evaluation.hygiene
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{
                        $t('form_details.evaluation_securite')
                      }}</strong>
                      <p
                        v-html="
                          pvFormDataAV &&
                          pvFormDataAV.evaluation &&
                          pvFormDataAV.evaluation.securite
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{
                        $t('form_details.evaluation_autres')
                      }}</strong>
                      <p
                        v-html="
                          pvFormDataAV &&
                          pvFormData.evaluation &&
                          pvFormData.evaluation.autres
                        "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row>
              {{ pvData && pvData.unite_logement }}
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th>
                    {{
                      finalPvForm && finalPvForm.type == 'classment'
                        ? 'Conformité aux normes de classement'
                        : 'Recapitulatif du contrôle'
                    }}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div v-if="finalPvForm && finalPvForm.data == null">
                        <small>{{ $t('form_details.no_results') }} </small>
                      </div>
                      <div v-else>
                        <div v-if="finalPvForm">
                          <div
                            v-if="
                              finalPvForm.type == 'audit' ||
                              finalPvForm.type == 'technical'
                            "
                          >
                            <b-collapse id="collapse-1" visible class="mt-1">
                              <ResultsOutputVisite
                                :results="finalPvForm.data"
                              />
                            </b-collapse>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <th>{{ $t('form_details.improvement') }}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong
                        >{{ $t('form_details.agence_plan')
                        }}{{
                          pvFormDataAV &&
                          pvFormDataAV.evaluation &&
                          pvFormDataAV.evaluation.plan
                        }}
                        :</strong
                      >
                      <p
                        v-html="
                          pvFormDataAV &&
                          pvFormDataAV.recommandation &&
                          pvFormDataAV.recommandation.norme
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{
                        $t('form_details.evaluation_hygiene')
                      }}</strong>
                      <p
                        v-html="
                          pvFormDataAV &&
                          pvFormDataAV.recommandation &&
                          pvFormDataAV.recommandation.hygiene
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{
                        $t('form_details.evaluation_securite')
                      }}</strong>
                      <p
                        v-html="
                          pvFormDataAV &&
                          pvFormDataAV.recommandation &&
                          pvFormDataAV.recommandation.securite
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{
                        $t('form_details.evaluation_autres')
                      }}</strong>
                      <p
                        v-html="
                          pvFormDataAV &&
                          pvFormDataAV.recommandation &&
                          pvFormDataAV.recommandation.autres
                        "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <table class="table table-bordered">
                <thead class="head_bg">
                  <tr>
                    <th colspan="3">
                      {{ $t('form_details.pic_establishment') }}
                    </th>
                  </tr>
                  <tr>
                    <th>IMAGE</th>
                    <th>{{ $t('form_details.comment') }}</th>
                  </tr>
                </thead>
                <tbody
                  v-if="
                    finalPvForm &&
                    finalPvForm.files &&
                    finalPvForm.files.length == 0
                  "
                >
                  <tr class="text-center">
                    <td clospan="">{{ $t('form_details.no_image') }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr
                    v-for="(file, indexF) in finalPvForm && finalPvForm.files"
                    :key="indexF"
                  >
                    <td class="d-flex justify-content-center">
                      <img
                        :src="file.base64"
                        alt="Selected Image"
                        width="200px"
                      />
                    </td>
                    <td>{{ file.comment }}</td>
                  </tr>
                </tbody>
              </table>
            </b-row>
          </div>
        </section>

        <!-- <hr class="invoice-spacing" /> -->
      </section>
    </vue-html2pdf>

    <!-- send mail side bar  -->
    <invoice-sidebar-send-invoice
      v-if="validOutput"
      :form-data="validOutput"
      :pdf="pdf"
      :current-subject="subject"
      :file-name="fileName"
    />
    <invoice-sidebar-add-payment />
  </section>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BBadge,
  BSpinner,
  BModal,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInput,
  BProgress,
  BFormTextarea,
  BFormGroup,
  BCollapse,
  VBTooltip,
  BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { VueEditor } from 'vue2-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapMutations } from 'vuex'
import { ContentLoader } from 'vue-content-loader'
import VueHtml2pdf from 'vue-html2pdf'
// import html2pdf from "html2pdf.js";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppLogo from '@/components/AppLogo.vue'
import utilsService from '@/services/utils/utils.service'
import formulaireStoreModule from '@/store/formulaire'
import evaluationsStoreModule from '@/store/evaluations'
import usersStoreModule from '@/store/users'
import demandesStoreModule from '@/store/demandes'
import visitesStoreModule from '@/store/visites'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import ResultsOutput from '@/components/ResultOutput.vue'
import ResultsOutputVisite from '@/components/ResultOutputVisite.vue'
import ResultsOutput2 from '@/components/ResultOutput2.vue'
// import CKEditor from '@ckeditor/ckeditor5-vue'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder'

// ClassicEditor
//   .create(document.querySelector('#editor'), {
//     plugins: [UploadAdapter],
//     toolbar: [], // ...Votre configuration de la barre d'outils
//     ckfinder: {
//       uploadUrl: '', // URL_de_votre_point_de-terminaison_d_upload
//     },
//   })
//   .then(editor => {
//     console.log('Editor was initialized', editor)
//     editor.isReadOnly = false // Rend l'éditeur en lecture seule
//   })
//   .catch(error => {
//     console.error(error)
//   })

import localstorageService from '@/services/localstorage/localstorage.service'
import Requests_states from './requests_states.js'

import invoiceStoreModule from '../invoice/invoiceStoreModule'
import InvoiceSidebarAddPayment from '../invoice/InvoiceSidebarAddPayment.vue'
import InvoiceSidebarSendInvoice from '../invoice/InvoiceSidebarSendInvoice.vue'
// import relativeTime from "dayjs/plugin/relativeTime";
// import dayjs from "dayjs";
// dayjs.locale("fr");
// dayjs.extend(relativeTime);

// Importez le composant CKEditor et la build que vous souhaitez utiliser
// ueh
const DetailsUEH_classement = () =>
  import(
    '@/views/apps/formulaires/ueh/demande_classement/DetailsUEH_classement.vue'
  )
const PV_UEH_classement = () =>
  import(
    '@/views/apps/formulaires/ueh/demande_classement/PV_UEH_classement.vue'
  )
const CheckList_UEH_classement = () =>
  import(
    '@/views/apps/formulaires/ueh/demande_classement/CheckList_UEH_classement.vue'
  )
const DetailsUEH_expolitation_classement = () =>
  import(
    '@/views/apps/formulaires/ueh/demande_exploitation_classement/DetailsUEH_expolitation_classement.vue'
  )
const PV_UEH_exploitation_classement = () =>
  import(
    '@/views/apps/formulaires/ueh/demande_exploitation_classement/PV_UEH_exploitation_classement.vue'
  )
const CheckList_UEH_exploitation_classement = () =>
  import(
    '@/views/apps/formulaires/ueh/demande_exploitation_classement/CheckList_UEH_exploitation_classement.vue'
  )
const DetailsUEH_notification_classement = () =>
  import(
    '@/views/apps/formulaires/ueh/demande_notification_classement/DetailsUEH_notification_classement.vue'
  )
const PV_UEH_notification_classement = () =>
  import(
    '@/views/apps/formulaires/ueh/demande_notification_classement/PV_UEH_notification_classement.vue'
  )
const CheckList_UEH_notification_classement = () =>
  import(
    '@/views/apps/formulaires/ueh/demande_notification_classement/CheckList_UEH_notification_classement.vue'
  )
const DetailsUEH_reclassement = () =>
  import(
    '@/views/apps/formulaires/ueh/demande_reclassement/DetailsUEH_reclassement.vue'
  )
const PV_UEH_reclassement = () =>
  import(
    '@/views/apps/formulaires/ueh/demande_reclassement/PV_UEH_reclassement.vue'
  )
const CheckList_UEH_reclassement = () =>
  import(
    '@/views/apps/formulaires/ueh/demande_reclassement/CheckList_UEH_reclassement.vue'
  )
const DetailsUEH_renouvellement = () =>
  import(
    '@/views/apps/formulaires/ueh/demande_renouvellement/DetailsUEH_renouvellement.vue'
  )
const PV_UEH_renouvellement = () =>
  import(
    '@/views/apps/formulaires/ueh/demande_renouvellement/PV_UEH_renouvellement.vue'
  )
const CheckList_UEH_renouvellement = () =>
  import(
    '@/views/apps/formulaires/ueh/demande_renouvellement/CheckList_UEH_renouvellement.vue'
  )
// uer
const DetailsUER_renouvellement_classement = () =>
  import(
    '@/views/apps/formulaires/uer/demande_renouvellement_classement/DetailsUER_renouvellement_classement.vue'
  )
const PV_UER_renouvellement_classement = () =>
  import(
    '@/views/apps/formulaires/uer/demande_renouvellement_classement/PV_UER_renouvellement_classement.vue'
  )
const CheckList_UER_renouvellement_classement = () =>
  import(
    '@/views/apps/formulaires/uer/demande_renouvellement_classement/CheckList_UER_renouvellement_classement.vue'
  )
const DetailsUER_autorisation = () =>
  import(
    '@/views/apps/formulaires/uer/demande_autorisation/DetailsUER_autorisation.vue'
  )
const PV_UER_autorisation = () =>
  import(
    '@/views/apps/formulaires/uer/demande_autorisation/PV_UER_autorisation.vue'
  )
const CheckList_UER_autorisation = () =>
  import(
    '@/views/apps/formulaires/uer/demande_autorisation/CheckList_UER_autorisation.vue'
  )
const DetailsUER_classement = () =>
  import(
    '@/views/apps/formulaires/uer/demande_classement/DetailsUER_classement.vue'
  )
const PV_UER_classement = () =>
  import(
    '@/views/apps/formulaires/uer/demande_classement/PV_UER_classement.vue'
  )
const CheckList_UER_classement = () =>
  import(
    '@/views/apps/formulaires/uer/demande_classement/CheckList_UER_classement.vue'
  )
const DetailsUER_renouvellement = () =>
  import(
    '@/views/apps/formulaires/uer/demande_renouvellement/DetailsUER_renouvellement.vue'
  )
const PV_UER_renouvellement = () =>
  import(
    '@/views/apps/formulaires/uer/demande_renouvellement/PV_UER_renouvellement.vue'
  )
const CheckList_UER_renouvellement = () =>
  import(
    '@/views/apps/formulaires/uer/demande_renouvellement/CheckList_UER_renouvellement.vue'
  )
const DetailsUER_notification = () =>
  import(
    '@/views/apps/formulaires/uer/notifications/DetailsUER_notification.vue'
  )
const PV_UER_notification = () =>
  import('@/views/apps/formulaires/uer/notifications/PV_UER_notification.vue')
const CheckList_UER_notification = () =>
  import(
    '@/views/apps/formulaires/uer/notifications/CheckList_UER_notification.vue'
  )
// uegt
const DetailsUEGT_affectation = () =>
  import(
    '@/views/apps/formulaires/uegt/demande_affectation/DetailsUEGT_affectation.vue'
  )
const PV_UEGT_affectation = () =>
  import(
    '@/views/apps/formulaires/uegt/demande_affectation/PV_UEGT_affectation.vue'
  )
const CheckList_UEGT_affectation = () =>
  import(
    '@/views/apps/formulaires/uegt/demande_affectation/CheckList_UEGT_affectation.vue'
  )
const DetailsUEGT_carte = () =>
  import('@/views/apps/formulaires/uegt/demande_carte/DetailsUEGT_carte.vue')
const PV_UEGT_carte = () =>
  import('@/views/apps/formulaires/uegt/demande_carte/PV_UEGT_carte.vue')
const CheckList_UEGT_carte = () =>
  import('@/views/apps/formulaires/uegt/demande_carte/CheckList_UEGT_carte.vue')
const DetailsUEGT_exercice = () =>
  import(
    '@/views/apps/formulaires/uegt/demande_exercice/DetailsUEGT_exercice.vue'
  )
const PV_UEGT_exercice = () =>
  import('@/views/apps/formulaires/uegt/demande_exercice/PV_UEGT_exercice.vue')
const CheckList_UEGT_exercice = () =>
  import(
    '@/views/apps/formulaires/uegt/demande_exercice/CheckList_UEGT_exercice.vue'
  )
const DetailsUEGT_renouvellement_carte = () =>
  import(
    '@/views/apps/formulaires/uegt/demande_renouvellement_carte/DetailsUEGT_renouvellement_carte.vue'
  )
const PV_UEGT_renouvellement_carte = () =>
  import(
    '@/views/apps/formulaires/uegt/demande_renouvellement_carte/PV_UEGT_renouvellement_carte.vue'
  )
const CheckList_UEGT_renouvellement_carte = () =>
  import(
    '@/views/apps/formulaires/uegt/demande_renouvellement_carte/CheckList_UEGT_renouvellement_carte.vue'
  )
const DetailsUEGT_specialite = () =>
  import(
    '@/views/apps/formulaires/uegt/demande_specialite/DetailsUEGT_specialite.vue'
  )
const PV_UEGT_specialite = () =>
  import(
    '@/views/apps/formulaires/uegt/demande_specialite/PV_UEGT_specialite.vue'
  )
const CheckList_UEGT_specialite = () =>
  import(
    '@/views/apps/formulaires/uegt/demande_specialite/CheckList_UEGT_specialite.vue'
  )
// ueav
const DetailsUEAV_licence = () =>
  import(
    '@/views/apps/formulaires/ueav/demande_licence/DetailsUEAV_licence.vue'
  )
const PV_UEAV_licence = () =>
  import('@/views/apps/formulaires/ueav/demande_licence/PV_UEAV_licence.vue')
const CheckList_UEAV_licence = () =>
  import(
    '@/views/apps/formulaires/ueav/demande_licence/CheckList_UEAV_licence.vue'
  )
const DetailsUEAV_succursale = () =>
  import(
    '@/views/apps/formulaires/ueav/demande_succursale/DetailsUEAV_succursale.vue'
  )
const PV_UEAV_succursale = () =>
  import(
    '@/views/apps/formulaires/ueav/demande_succursale/PV_UEAV_succursale.vue'
  )
const CheckList_UEAV_succursale = () =>
  import(
    '@/views/apps/formulaires/ueav/demande_succursale/CheckList_UEAV_succursale.vue'
  )
const DetailsUEAV_renouvellement_licence = () =>
  import(
    '@/views/apps/formulaires/ueav/demande_renouvellement_licence/DetailsUEAV_renouvellement_licence.vue'
  )
const PV_UEAV_renouvellement_licence = () =>
  import(
    '@/views/apps/formulaires/ueav/demande_renouvellement_licence/PV_UEAV_renouvellement_licence.vue'
  )
const CheckList_UEAV_renouvellement_licence = () =>
  import(
    '@/views/apps/formulaires/ueav/demande_renouvellement_licence/CheckList_UEAV_renouvellement_licence.vue'
  )
const DetailsUEAV_notification = () =>
  import(
    '@/views/apps/formulaires/ueav/notifications/DetailsUEAV_notification.vue'
  )
const PV_UEAV_notification = () =>
  import('@/views/apps/formulaires/ueav/notifications/PV_UEAV_notification.vue')
const CheckList_UEAV_notification = () =>
  import(
    '@/views/apps/formulaires/ueav/notifications/CheckList_UEAV_notification.vue'
  )
export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BBadge,
    BSpinner,
    BModal,
    BFormCheckbox,
    BFormCheckboxGroup,
    BProgress,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BCollapse,
    BFormSelect,
    vSelect,
    AppTimeline,
    AppTimelineItem,
    flatPickr,
    InvoiceSidebarAddPayment,
    InvoiceSidebarSendInvoice,
    // ueh
    DetailsUEH_classement,
    PV_UEH_classement,
    CheckList_UEH_classement,
    DetailsUEH_expolitation_classement,
    PV_UEH_exploitation_classement,
    CheckList_UEH_exploitation_classement,
    DetailsUEH_notification_classement,
    PV_UEH_notification_classement,
    CheckList_UEH_notification_classement,
    DetailsUEH_reclassement,
    PV_UEH_reclassement,
    CheckList_UEH_reclassement,
    DetailsUEH_renouvellement,
    PV_UEH_renouvellement,
    CheckList_UEH_renouvellement,
    // uer
    DetailsUER_renouvellement_classement,
    PV_UER_renouvellement_classement,
    CheckList_UER_renouvellement_classement,
    DetailsUER_classement,
    PV_UER_classement,
    CheckList_UER_classement,
    DetailsUER_autorisation,
    PV_UER_autorisation,
    CheckList_UER_autorisation,
    DetailsUER_renouvellement,
    PV_UER_renouvellement,
    CheckList_UER_renouvellement,
    DetailsUER_notification,
    PV_UER_notification,
    CheckList_UER_notification,
    // uegt
    DetailsUEGT_affectation,
    PV_UEGT_affectation,
    CheckList_UEGT_affectation,
    DetailsUEGT_carte,
    PV_UEGT_carte,
    CheckList_UEGT_carte,
    DetailsUEGT_exercice,
    PV_UEGT_exercice,
    CheckList_UEGT_exercice,
    DetailsUEGT_renouvellement_carte,
    PV_UEGT_renouvellement_carte,
    CheckList_UEGT_renouvellement_carte,
    DetailsUEGT_specialite,
    PV_UEGT_specialite,
    CheckList_UEGT_specialite,
    // ueav
    DetailsUEAV_licence,
    PV_UEAV_licence,
    CheckList_UEAV_licence,
    DetailsUEAV_succursale,
    PV_UEAV_succursale,
    CheckList_UEAV_succursale,
    DetailsUEAV_renouvellement_licence,
    PV_UEAV_renouvellement_licence,
    CheckList_UEAV_renouvellement_licence,
    DetailsUEAV_notification,
    PV_UEAV_notification,
    CheckList_UEAV_notification,

    AppLogo,

    ContentLoader,
    VueHtml2pdf,
    VueEditor,
    ValidationProvider,
    ValidationObserver,
    ResultsOutput,
    ResultsOutput2,
    ResultsOutputVisite,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'users', module: usersStoreModule },
      { path: 'formulaire', module: formulaireStoreModule },
      { path: 'evaluations', module: evaluationsStoreModule },
      { path: 'demandes', module: demandesStoreModule },
      { path: 'visites', module: visitesStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  data() {
    const {
      currentUserId,
      isAdmin,
      isMtcaAdmin,
      isMtcaAgent,
      isOtAdmin,
      isOtAgent,
      isAdministrationUser,
      isAuditor,
      isOperator,
      isMinister,
    } = utilsService.currentUserUtils()
    return {
      // editor: ClassicEditor,www
      editorData: "<p>Contenu initial de l'éditeur</p>",
      editorConfig: {
        isReadOnly: false,
      },

      isLoading: false,
      isLoadingValideStat: false,
      isLoading_update_state: false,
      isLoading_download_bilan: false,
      isLoading_meeting_informations: false,
      isLoading_update_rdv_audit: false,
      isLoading_update_treatment_agent: false,
      isLoading_update_rdv_technical_visit: false,
      isLoading_update_rdv_classification_visit: false,
      isLoading_update_object: false,
      isLoading_update_check_status: false,
      isLoading_update_checklist: false,
      isLoading_update_comments: false,
      isLoading_statuses_section: false,
      loading_steps_panel: false,
      invalidFormInput: false,
      isFetchingFormDetails: false,
      validOutput: {},
      next_step_comment: '',

      treatment_agent_id: '',
      treatment_agent_id_ot: '',
      date_rendez_vous_audit: '',

      technical_visitor_id: '',
      followers_technical_visitor_ids: [],
      date_rendez_vous_technical_visit: '',

      classification_visitor_id: '',
      followers_classification_visitor_ids: [],

      auditor_id: '',
      followers_auditor_id: [],

      morality_investigation_responsable_id: '',
      applicant_responsable_id: '',

      can_treate_mtca: null,
      can_treate_ot: null,
      current_id: null,
      checklist_object_for_print: null,
      options_mtca_admins: [],
      options_mtca_agents: [],
      options_ot_admins: [],
      options_ot_agents: [],
      options_auditors: [],
      curr_decisions: [],
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ align: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        // ['image', 'link'],
      ],
      check_object: null,
      checklist_object: null,
      pdf: null,
      canDownload: false,
      current_demand_id: '',

      visit_id: null,
      classment_id: null,
      audit_id: null,

      finalPvForm: null,
      pvData: null,
      pvDataGuide: null,
      pvFormControleTechnique: null,
      pvFormControleClassement: null,
      pvFormAudit: null,
      filesTech: [],
      filesClass: [],
      filesAudit: [],
      pvFormData: {
        coordonnees_etablissement: {
          nbrs_chambres: null,
          nbrs_lits: null,
          services: {
            restaurant: null,
            bar: null,
            spa: null,
            autres: null,
          },
        },
        evaluation: {
          norme_classement: null,
          hygiene: null,
          securite: null,
          autres: null,
        },
        infos_complementaires: null,
        recommandation: {
          norme_classement: null,
          hygiene: null,
          securite: null,
          autres: null,
        },
      },
      pvFormDataGuide: {
        coordonnees_etablissement: {
          categorie_demandee: null,
          typologie: null,
          localite: null,
        },
        evaluation: {
          plan1: null,
          plan2: null,
          plan3: null,
          plan4: null,
          plan1Details: null,
          plan2Details: null,
          plan3Details: null,
          plan4Details: null,
        },
        recommandation: {
          plan1: null,
          plan2: null,
          plan3: null,
          plan4: null,
          plan1Details: null,
          plan2Details: null,
          plan3Details: null,
          plan4Details: null,
        },
      },
      pvFormDataAV: {
        coordonnees_etablissement: {
          services: null,
        },
        evaluation: {
          plan: null,
          norme: null,
          hygiene: null,
          securite: null,
          autres: null,
        },
        recommandation: {
          norme: null,
          hygiene: null,
          securite: null,
          autres: null,
        },
      },
      allUsersHaveSignedTechnical: false,
      allUsersHaveSignedClassment: false,
      allUsersHaveSignedAudit: false,
      selectedForm: null,
      reversedStatuses: [],
      subject: '',
      fileName: '',
      meeting_with_applicant_info: '',
      currentUserId,
      isAdmin,
      isMtcaAdmin,
      isMtcaAgent,
      isOtAdmin,
      isOtAgent,
      isAdministrationUser,
      isAuditor,
      isOperator,
      isMinister,
      states: null,
      current_state: null,
      next_state: null,
      should_have_auto_eval: null,
      request_have_auto_eval: null,
      request_have_audit: null,
      request_technical_visit: null,
      can_update: null,
      can_update_resquest_data: null,
      evaluationResult: null,
      auditResult: null,
      visitResult: null,
      forPrint: false,
      signature: null,
      isDownloading: false,
      file_for_vizualisation: null,
      show_vizualisation_section: false,
      userData: null,
    }
  },
  computed: {
    mustStopTreatment() {
      if (!this.current_state || !this.current_state.stop || !this.validOutput)
        return false
      const type_etablissement =
        this.validOutput && this.validOutput.data.type_etablissement
      // if(!this.current_state || !this.current_state )
      // if(!this.current_state || this.current_state.stop || !this.validOutput) return false
      return this.current_state.stop.includes(type_etablissement)
    },
    estUneFicheCliente() {
      return this.selectedForm && this.selectedForm.type === 'client'
    },
    estUneFicheFrontiere() {
      return this.selectedForm && this.selectedForm.sousType === 'frontiere'
    },
    canShowCheckButtons() {
      return (
        this.current_state && this.current_state.status === 'treatment_start'
      )
    },
    can_start_request_treatment() {
      if (
        this.isAdmin ||
        this.isMtcaAdmin ||
        this.isMtcaAgent ||
        this.isOtAdmin
      )
        return true
      return false
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.userData = localstorageService.getUserData()
        const { slug, id } = this.$route.params
        console.log('slug, id::: ', slug, id);
        this.current_demand_id = id
        this.selectedForm = utilsService.getFormulaireByCodeClient(slug)
        console.log('this.selectedForm::: ', this.selectedForm)
        this.states = this.selectedForm.process_steps // this.states = Requests_states;
        this.loadData()
        // load secondaries data ----------------------------------------------------------------------
        // load technic agents ------------------------------------------------------------
        const params_technic_agents = {
          role: 'TA',
        }
        this.action_searchUsers(params_technic_agents)
          .then(async (response) => {
            if (response.status === 200) {
              this.options_ot_agents = response.data.data
              this.options_ot_agents.forEach((auditeur) => {
                auditeur.label = `${auditeur.lastname} ${auditeur.firstname}, ${auditeur.email}`
              })
            }
          })
          .catch((err) => {
            console.log('err::: ', err)
          })
        // load technic admins ------------------------------------------------------------
        const params_technic_admins = {
          role: 'TAD',
        }
        this.action_searchUsers(params_technic_admins)
          .then(async (response) => {
            if (response.status === 200) {
              this.options_ot_admins = response.data.data
              this.options_ot_admins.forEach((auditeur) => {
                auditeur.label = `${auditeur.lastname} ${auditeur.firstname}, ${auditeur.email}`
              })
            }
          })
          .catch((err) => {
            console.log('err::: ', err)
          })
        // load mtca agents ------------------------------------------------------------
        const params_mtca_agents = {
          role: 'AUAT',
        }
        this.action_searchUsers(params_mtca_agents)
          .then(async (response) => {
            if (response.status === 200) {
              this.options_mtca_agents = response.data.data
              this.options_mtca_agents.forEach((auditeur) => {
                auditeur.label = `${auditeur.lastname} ${auditeur.firstname}, ${auditeur.email}`
              })
            }
          })
          .catch((err) => {
            console.log('err::: ', err)
          })
        // load mtca admins ------------------------------------------------------------
        const params_mtca_admins = {
          role: 'UAT',
        }
        this.action_searchUsers(params_mtca_admins)
          .then(async (response) => {
            if (response.status === 200) {
              this.options_mtca_admins = response.data.data
              this.options_mtca_admins.forEach((auditeur) => {
                auditeur.label = `${auditeur.lastname} ${auditeur.firstname}, ${auditeur.email}`
              })
            }
          })
          .catch((err) => {
            console.log('err::: ', err)
          })
        // load auditors ------------------------------------------------------------
        const params_auditors = {
          role: 'AUD',
        }
        this.action_searchUsers(params_auditors)
          .then(async (response) => {
            if (response.status === 200) {
              this.options_auditors = response.data.data
              this.options_auditors.forEach((auditeur) => {
                auditeur.label = `${auditeur.lastname} ${auditeur.firstname}, ${auditeur.email}`
              })
            }
          })
          .catch((err) => {
            console.log('err::: ', err)
          })
        // load secondaries data ----------------------------------------------------------------------
      },
    },
    // "validOutput.statuses": {
    //   immediate: true,
    //   deep: true,
    //   handler(val, old) {
    //     // console.log('::: validOutput.statuses change 🔴🔴🔴')
    //     this.isLoading_statuses_section = true
    //     setTimeout(() => {
    //       this.isLoading_statuses_section = false
    //     }, 800);
    //   },
    // },
    // allUsersHaveSignedTechnical: {
    //   immediate: true,
    //   handler(val, old) {
    //     if (
    //       val &&
    //       this.selectedForm.have_autorisation_visit &&
    //       this.current_state.show_result == 'have_autorisation_visit'
    //     ) {
    //       console.log('::: can validate 🟢')
    //       this.$refs.update_status_button.click()
    //     }
    //   },
    // },
    // allUsersHaveSignedClassment: {
    //   immediate: true,
    //   handler(val, old) {
    //     if (
    //       val &&
    //       this.selectedForm.have_classification_visit &&
    //       this.current_state.show_result == 'have_classification_visit'
    //     ) {
    //       console.log('::: can validate 🟠')
    //     }
    //   },
    // },
    // allUsersHaveSignedAudit: {
    //   immediate: true,
    //   handler(val, old) {
    //     if (
    //       val &&
    //       this.selectedForm.have_exam_visit &&
    //       this.current_state.show_result == 'have_exam_visit'
    //     ) {
    //       console.log('::: can validate 🔴')
    //     }
    //   },
    // },
  },
  beforeDestroy() {
    // unregisterStoreModule(this.requiredStoreModules);
  },
  mounted() {},
  methods: {
    ...mapMutations('demandes', {
      set_rdv_params: 'setRdvParams',
    }),
    ...mapActions('users', {
      action_searchUsers: 'searchUsers',
    }),
    ...mapActions('visites', {
      action_updateVisite: 'updateVisite',
    }),
    ...mapActions('formulaire', {
      action_getFormulaire: 'getFormulaire',
      action_updateFormulaire: 'updateFormulaire',
      action_updateFormulaireFormData: 'updateFormulaireFormData',
      action_downloadBilan: 'downloadBilan',
      action_downloadQa: 'downloadQaEvaluation',
      action_downloadResult: 'downloadEvaluationResult',
    }),
    ...mapActions('evaluations', {
      action_downloadQa: 'downloadQaEvaluation',
      action_downloadResult: 'downloadEvaluationResult',
      action_getEvaluation: 'getEvaluation',
    }),
    // ---------------------------------------------------------------
    async generatePDF() {
      // await this.$refs.myIframe.generatePdf();
    },
    currentDate() {
      const today = new Date()
      const day = String(today.getDate()).padStart(2, '0') // Add leading zero if needed
      const month = today.toLocaleString('fr-FR', { month: 'short' }) // Get short month name
      const year = today.getFullYear().toString()

      return `${day} ${month} ${year}`
    },
    convertedDateTime(originalDateTime) {
      const parts = originalDateTime.split(' ')
      const dateParts = parts[0].split('-') // Split date by "-"
      const year = dateParts[2]
      const month = dateParts[1].padStart(2, '0') // Add leading zero if needed
      const day = dateParts[0].padStart(2, '0') // Add leading zero if needed
      const timeParts = parts[1]
      return `${year}-${month}-${day} ${timeParts}`
    },
    formatDatepv(pv_date) {
      const date = new Date(this.convertedDateTime(pv_date))
      // Extract day, month, year, hour, and minute
      const day = String(date.getDate()).padStart(2, '0')
      const month = date.toLocaleString('fr-FR', { month: 'short' })
      const year = date.getFullYear()
      const hour = String(date.getHours()).padStart(2, '0')
      const minute = String(date.getMinutes()).padStart(2, '0')
      return `${day} ${month} ${year} à ${hour}:${minute}`
    },
    loadData() {
      this.isFetchingFormDetails = true
      this.action_getFormulaire(this.current_demand_id)
        .then((result) => {
          console.log('loadData::: ⭕️🩸⭕️', result.data)
          this.should_have_auto_eval = this.should_have_auto_eval_control(
            result.data.type_demande.code
          )
          this.validOutput = result.data
          this.current_id = result.data.id
          // load media -----------------------------------------------------
          // attached_files
          // media
          this.loadPV(this.validOutput)
          this.validOutput.attached_files = []
          for (let index = 0; index < result.data.media.length; index++) {
            const curr_media = result.data.media[index]
            this.validOutput.attached_files.push(curr_media)
          }
          // load auto eval -------------------------------------------------
          // this.request_have_auto_eval = result.data.auto_evaluation || null
          // if (this.request_have_auto_eval) {
          //   this.action_getEvaluation(this.request_have_auto_eval.id)
          //     .then(async (response) => {
          //       if (response.status === 200) {
          //         this.request_have_auto_eval = response.data
          //       }
          //     })
          //     .catch((err) => {
          //       console.log('err::: ', err)
          //     })
          // }
          // load audit -------------------------------------------------
          // this.request_have_audit = result.data.audit || null
          // if (this.request_have_audit) {
          //   this.action_getEvaluation(this.request_have_audit.id)
          //     .then(async (response) => {
          //       if (response.status === 200) {
          //         this.request_have_audit = response.data
          //       }
          //     })
          //     .catch((err) => {
          //       console.log('err::: ', err)
          //     })
          // }
          // load technical visit -------------------------------------------------
          // this.request_technical_visit = result.data.visits || null
          // this.visit_id = result.data.technical_visit || null
          // this.classment_id = result.data.classification_visit || null
          // this.audit_id = result.data.audit || null

          this.validOutput.statuses = this.customCloneDeep(result.data.statuses)
          this.reversedStatuses = []
          for ( let index = this.validOutput.statuses.length - 1; index >= 0; index-- ) {
            this.reversedStatuses.push(this.validOutput.statuses[index])
          }
          this.validOutput.created_at = result.data.created_at

          this.validOutput.checklist_object = JSON.parse(
            result.data.checklist_data
          )
          if (this.validOutput.checklist_object) {
            this.validOutput.checklist_object.signature_file =
              result.data.checklist_signature_file
          }
          this.checklist_object_for_print = this.validOutput.checklist_object

          this.validOutput.updated_at = result.data.updated_at
          this.treatment_agent_id = result.data.treatment_agent_id
          this.can_treate_mtca = this.is_user_connected(this.treatment_agent_id)
          this.treatment_agent_id_ot = result.data.treatment_agent_id_ot
          this.can_treate_ot = this.is_user_connected(
            this.treatment_agent_id_ot
          )
          this.date_rendez_vous_audit = result.data.date_rendez_vous_audit || '' // audit
          this.auditor_id = result.data.auditor_id || '' // audit
          this.date_rendez_vous_technical_visit =
            result.data.date_rendez_vous_technical_visit || '' // technical visit
          this.technical_visitor_id = result.data.technical_visitor_id || '' // technical visit
          this.applicant_responsable_id = result.data.technical_visitor_id || ''
          this.morality_investigation_responsable_id =
            result.data.classification_visitor_id || ''
          result.data.visits.map((x) =>
            x.visitors.map((y) => {
              this.followers_technical_visitor_ids.push(y.id)
            })
          ) // technical visit

          this.classification_visitor_id =
            result.data.classification_visitor_id || '' // technical visit

          result.data.visits.map((x) =>
            x.visitors.map((y) => {
              this.followers_classification_visitor_ids.push(y.id)
            })
          )

          this.validOutput.reference = result.data.reference
          this.validOutput.comments = result.data.comments || ''
          // this.check_object = this.validOutput.check_object =
          //   this.removecheck_object_attributes(result.data.check_object);
          this.check_object = this.validOutput.check_object =
            result.data.check_object
          // this.extractAttributes(
          //   result.data.check_object,
          //   this.selectedForm.attributs_editables
          // )
          this.isFetchingFormDetails = false
          this.load_current_state()
          this.fileName = `REF-${this.validOutput.numero_demande}-${this.selectedForm.title}`
          // console.log('loadData this.validOutput::: ⭕️', this.validOutput)
          // this.checkVisa()
        })
        .catch((err) => {
          console.log('err::: ', err)
          this.isFetchingFormDetails = false
        })
    },
    decodeBase64ToImage(base64String) {
      const bytes = atob(base64String)
      const mimeType = bytes.split(',')[0].split(':')[1]
      const buffer = new ArrayBuffer(bytes.length)
      const array = new Uint8Array(buffer)
      for (let i = 0; i < bytes.length; ++i) {
        array[i] = bytes.charCodeAt(i)
      }
      const blob = new Blob([buffer], { type: mimeType })
      const url = URL.createObjectURL(blob)
      const img = new Image()
      img.onload = () => {
        URL.revokeObjectURL(url) // Revoke the temporary URL
      }
      img.src = url
      return img // You can return the image element or use it directly
    },
    async loadPV(result) {
      this.pvDataGuide = {
        etablissement: result.user.profile.nom_etablissement,
        departement: result.user.profile.departement,
        commune: result.user.profile.commune,
        site_web: result.user.profile.website,
        telephone: result.user.profile.telephone_etablissement,
        email: result.user.profile.email_etablissement,

        promoteur_fullname: result.user.fullname,
        email_promoteur: result.user.profile.email_promoteur,
        telephone_promoteur: result.user.profile.telephone_promoteur,
      }

      this.pvData = {
        etablissement: result.user.profile.nom_etablissement,
        departement: result.user.profile.departement,
        commune: result.user.profile.commune,
        site_web: result.user.profile.website,
        telephone: result.user.profile.telephone_etablissement,
        email: result.user.profile.email_etablissement,
        classement_prev: `${result.user.profile.type_etablissement} ${result.user.profile.classement}`,
        classement_propose: `${result.type_etablissement} ${result.classement}`,
        classement: `${result.data.type_etablissement} ${result.data.classement}`,
        promoteur_fullname: result.user.fullname,
        email_promoteur: result.user.profile.email_promoteur,
        telephone_promoteur: result.user.profile.telephone_promoteur,
        unite_logement: result.data.unite_logement,
      }

      if (result.technical_visit !== null) {
        result.technical_visit.data.sections.map((e) => {
          e.questions.map((question) => {
            if (question.files !== null) {
              question.files.map((file) => {
                const base64 = `data:image/jpeg;base64,${file.base64}`
                this.filesTech.push({
                  base64,
                  comment: file.filename,
                })
              })
            }
          })
        })
      }
      if (result.audit !== null) {
        result.audit.data.sections.map((e) => {
          e.questions.map((question) => {
            if (question.files !== null) {
              question.files.map((file) => {
                const base64 = `data:image/jpeg;base64,${file.base64}`
                this.filesAudit.push({
                  base64,
                  comment: file.filename,
                })
              })
            }
          })
        })
      }

      this.pvFormControleTechnique = {
        files: this.filesTech,
        ...result.technical_visit,
      }
      this.pvFormControleClassement = {
        files: this.filesClass,
        ...result.classification_visit,
      }
      this.pvFormAudit = {
        files: this.filesAudit,
        ...result.audit,
      }
    },
    load_current_state() {
      this.loading_steps_panel = true
      const status_code = this.validOutput && this.validOutput.statuses[0].name
      for (let index = 0; index < this.states.length; index++) {
        const state = this.states[index]
        if (state.status === status_code) {
          this.current_state = state
          console.log(
            'this.current_state:::++++++++ ⭕️⭕️⭕️',
            this.current_state
          )
          // control if user can update request
          const user_can_update_request = this.user_can_update_request(
            this.validOutput.statuses
          )
          this.can_update = user_can_update_request
          this.can_update_resquest_data =
            this.current_state.request_update_section

          if (this.current_state.final_state) {
            this.next_state = null
            this.curr_decisions = []
            setTimeout(() => {
              this.loading_steps_panel = false
            }, 1000)
          } else {
            this.get_next_nodes()
          }
          break
        }
      }
      this.can_treate()
    },
    get_next_nodes() {
      if (this.current_state.next_decisions_options_count) {
        // console.log('::: next is decisions nodes ⭕️')
        // next is decisions nodes
        this.next_state = null
        // function ---------------------------------------------
        this.curr_decisions = []
        const { next_decisions_options_count } = this.current_state
        const index = this.states.findIndex(
          (object) => object.status === this.current_state.status
        )
        // load decisions
        let next_index = index
        const decisions = []
        for (let index = 0; index < next_decisions_options_count; index++) {
          next_index += 1
          decisions.push(this.states[next_index])
        }
        this.curr_decisions = decisions
        setTimeout(() => {
          this.loading_steps_panel = false
        }, 1000)
        // console.log(
        //   'this.curr_decisions:::++++++++ ⭕️⭕️⭕️ ',
        //   this.curr_decisions
        // )
      } else {
        // console.log('::: next is not decisions nodes ⭕️')
        // next is'nt decisions nodes
        this.curr_decisions = []
        const step = this.states.filter(
          (obj) => obj.status === this.current_state.next_step
        )
        this.next_state = step[0]
        setTimeout(() => {
          this.loading_steps_panel = false
        }, 1000)
        console.log('this.next_state:::++++++++ ⭕️⭕️⭕️ ', this.next_state)
      }
    },
    can_treate() {
      // console.log('::: ❌ 1')
      const {
        next_state,
        current_state,
        isOtAdmin,
        isMtcaAdmin,
        can_treate_ot,
        can_treate_mtca,
        treatment_agent_id_ot,
      } = this

      // console.log('::: ❌ 2')

      // Si le statut actuel est "on_hold", retourne true
      if (this.current_state.status == 'on_hold') return true
      if (this.current_state.status == 'accepted_request' && isOtAdmin) return true

      // Si next_state existe et a is_ot_validation
      if (next_state?.is_ot_validation) {
        if(treatment_agent_id_ot) return can_treate_ot
        else return true
      }
      // console.log('::: ❌ 3')
      // Si next_state existe mais n'a pas is_ot_validation
      if (next_state && !next_state.is_ot_validation) {
        return can_treate_mtca
      }
      // console.log('::: ❌ 4')
      // Si next_state n'existe pas et current_state a is_ot_validation
      if (!next_state && current_state.is_ot_validation) {
        return can_treate_ot
      }
      // console.log('::: ❌ 5')
      // Si next_state n'existe pas et current_state n'a pas is_ot_validation
      if (!next_state && !current_state.is_ot_validation) {
        return can_treate_mtca
      }
      // console.log('::: ❌')
      // Par défaut, retourne false
    },
    update_status(final_action, send_mail) {
      this.isLoading_update_state = true
      let data = {}
      if (!final_action) {
        data = {
          status: this.next_state.status,
          commentaire: this.next_step_comment,
        }
      } else {
        data = {
          status: final_action,
          commentaire: this.next_step_comment,
        }
      }
      if (this.curr_decisions.length === 0 && this.next_state.send_mail)
        data = { ...data, send_mail: true }
      console.log('update_status data::: ⭕️', data)
      // return
      this.action_updateFormulaire({
        id: this.current_demand_id,
        data,
      })
        .then((result) => {
          console.log('result::: ⭕️⭕️', result, result.data.statuses);
          if (result.status === 200) {
            this.next_step_comment = ''
            this.validOutput.statuses = this.customCloneDeep(result.data.statuses)
            this.reversedStatuses = []
            for ( let index = this.validOutput.statuses.length - 1; index >= 0; index-- ) {
              this.reversedStatuses.push(this.validOutput.statuses[index])
            }
            const user_can_update_request = this.user_can_update_request(
              this.validOutput.statuses
            )
            this.can_update = user_can_update_request
            this.isLoading_update_state = false
            this.load_current_state()
            // this.loadData()
            console.log(
              'this.current_state.status::: ',
              this.current_state.status
            )
            if (
              this.current_state.status == 'treatment_start' ||
              this.current_state.status == 'in_process_technical_control'
            ) {
              // return true
              this.$router.go()
            }

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modification enregistrée',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
          this.isLoading_update_state = false
        })
    },
    can_next_step_have_autorisation_visit() {},
    can_next_step_have_classification_visit() {},
    can_next_step_have_exam_visit() {},

    // ----------------------------------------------------------- files vizualisation
    would_see_file(data) {
      this.file_for_vizualisation = data
      this.show_vizualisation_section = true
    },
    async rdv_params(data) {
      await localstorageService.setRdvParams(data)
      this.$router.push({
        name: 'formulaires-rdv',
      })
    },
    // -----------------------------------------------------------
    update_treatment_agent() {
      this.isLoading_update_treatment_agent = true
      const data = {
        treatment_agent_id: this.treatment_agent_id,
      }
      this.action_updateFormulaire({
        id: this.current_demand_id,
        data,
      })
        .then((result) => {
          if (result.status === 200) {
            this.isLoading_update_treatment_agent = false
            this.treatment_agent_id = result.data.treatment_agent_id
            this.$refs['modal-meet-audit'].hide()
            this.can_treate()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Agent traitant modifié',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
          this.isLoading_update_treatment_agent = false
        })
    },
    update_treatment_agent_ot() {
      this.isLoading_update_treatment_agent = true
      const data = {
        treatment_agent_id_ot: this.treatment_agent_id_ot,
      }
      this.action_updateFormulaire({
        id: this.current_demand_id,
        data,
      })
        .then((result) => {
          if (result.status === 200) {
            // console.log('result.data::: ', result.data)
            this.isLoading_update_treatment_agent = false
            this.treatment_agent_id_ot = result.data.treatment_agent_id_ot
            this.$refs['modal-set-treatment-agent-ot'].hide()
            this.can_treate()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Agent traitant modifié',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
          this.isLoading_update_treatment_agent = false
        })
    },
    storeMeet_technical_visit() {
      if (!this.technical_visitor_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Veuillez préciser le responsable de la visite.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.isLoading_update_rdv_technical_visit = true
      const data = {
        // date_rendez_vous_technical_visit: this.date_rendez_vous_technical_visit,
        technical_visitors: {
          technical_visitor_id: this.technical_visitor_id,
          technical_visitors: this.followers_technical_visitor_ids,
        },
      }
      this.action_updateFormulaire({
        id: this.current_demand_id,
        data,
      })
        .then((result) => {
          if (result.status === 200) {
            // this.date_rendez_vous_technical_visit = result.data.date_rendez_vous_technical_visit
            this.isLoading_update_rdv_technical_visit = false
            this.$refs['modal-meet-technical-visit'].hide()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Responsable(s) de la visite technique enregistré',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
          this.isLoading_update_rdv_technical_visit = false
        })
    },
    checkVisa() {
      // console.log(
      //   'this.pvFormControleTechnique.type',
      //   this.pvFormControleTechnique
      // )
      if (
        this.pvFormControleTechnique &&
        this.pvFormControleTechnique.type == 'technical'
      ) {
        this.allUsersHaveSignedTechnical =
          this.pvFormControleTechnique.visas.every((user) => user.visa === true)
        if (
          this.allUsersHaveSignedTechnical &&
          this.selectedForm.have_autorisation_visit &&
          this.current_state.show_result == 'have_autorisation_visit'
        ) {
          // console.log('::: can validate 🟢')
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                'Tout les membres associés au PV du contrôle technique ont signés',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.update_status()
        }
      } else if (
        this.pvFormControleClassement &&
        this.pvFormControleClassement.type == 'classment'
      ) {
        this.allUsersHaveSignedClassment =
          this.pvFormControleClassement.visas.every(
            (user) => user.visa === true
          )
        if (
          this.allUsersHaveSignedClassment &&
          this.selectedForm.have_classification_visit &&
          this.current_state.show_result == 'have_classification_visit'
        ) {
          // console.log('::: can validate 🟠')
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                'Tout les membres associés au PV du contrôle classement ont signés',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.update_status()
        }
      } else if (
        this.pvFormControleAudit &&
        this.pvFormControleAudit.type == 'audit'
      ) {
        this.allUsersHaveSignedAudit = this.pvFormControleAudit.visas.every(
          (user) => user.visa === true
        )
        if (
          this.allUsersHaveSignedAudit &&
          this.selectedForm.have_exam_visit &&
          this.current_state.show_result == 'have_exam_visit'
        ) {
          // console.log('::: can validate 🔴')
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Tout les membres associés au PV du l'audit mystère ont signés",
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.update_status()
        }
      }
    },
    changeSignature(id, visas) {
      const data = {
        visas,
      }
      // console.log('visas', visas)
      this.action_updateVisite({
        id,
        data,
      })
        .then((result) => {
          if (result.status === 202) {
            this.checkVisa()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modification enregistrée',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
          this.isLoading_update_state = false
        })
    },
    updatePvData(id, check_object) {
      // console.log('visas', check_object)
      const data = {
        check_object: check_object,
      }
      this.action_updateVisite({
        id,
        data,
      })
        .then((result) => {
          if (result.status === 202) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: 'Modification enregistrée',
            //     icon: 'CheckIcon',
            //     variant: 'success',
            //   },
            // })
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
          this.isLoading_update_state = false
        })
    },
    storeMeet_applicant_responsable() {
      if (!this.applicant_responsable_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Veuillez préciser le responsable de la visite.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.isLoading_update_rdv_technical_visit = true
      const data = {
        // date_rendez_vous_technical_visit: this.date_rendez_vous_technical_visit,
        technical_visitor_id: this.applicant_responsable_id,
      }
      this.action_updateFormulaire({
        id: this.current_demand_id,
        data,
      })
        .then((result) => {
          if (result.status === 200) {
            // this.date_rendez_vous_technical_visit = result.data.date_rendez_vous_technical_visit
            this.isLoading_update_rdv_technical_visit = false
            this.$refs['modal-meet-with-applicant'].hide()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Responsable(s) de la visite technique enregistré',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
          this.isLoading_update_rdv_technical_visit = false
        })
    },
    storeMeet_morality_investigation_responsable() {
      if (!this.morality_investigation_responsable_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Veuillez préciser le responsable de la visite.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.isLoading_update_rdv_technical_visit = true
      const data = {
        // date_rendez_vous_technical_visit: this.date_rendez_vous_technical_visit,
        classification_visitor_id: this.morality_investigation_responsable_id,
      }
      this.action_updateFormulaire({
        id: this.current_demand_id,
        data,
      })
        .then((result) => {
          if (result.status === 200) {
            // this.date_rendez_vous_technical_visit = result.data.date_rendez_vous_technical_visit
            this.isLoading_update_rdv_technical_visit = false
            this.$refs['modal-morality-investigation'].hide()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Responsable(s) de la visite technique enregistré',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
          this.isLoading_update_rdv_technical_visit = false
        })
    },
    storeMeet_classification_visit() {
      if (!this.classification_visitor_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Veuillez préciser le responsable de la visite.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.isLoading_update_rdv_classification_visit = true
      const data = {
        classification_visitors: {
          classification_visitor_id: this.classification_visitor_id,
          classification_visitors: this.followers_classification_visitor_ids,
        },
        // date_rendez_vous_technical_visit: this.date_rendez_vous_technical_visit,
      }
      this.action_updateFormulaire({
        id: this.current_demand_id,
        data,
      })
        .then((result) => {
          if (result.status === 200) {
            this.isLoading_update_rdv_classification_visit = false
            this.$refs['modal-meet-classification-visit'].hide()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Responsable(s) de la visite de classement enregistré',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
          this.isLoading_update_rdv_classification_visit = false
        })
    },
    storeMeet_audit() {
      if (!this.auditor_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Veuillez préciser le responsable de l'audit mystère.",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.isLoading_update_rdv_technical_visit = true
      const data = {
        auditors: {
          auditor_id: this.auditor_id,
          auditors: this.followers_auditor_id,
        },
      }
      // return;
      this.action_updateFormulaire({
        id: this.current_demand_id,
        data,
      })
        .then((result) => {
          if (result.status === 200) {
            // this.date_rendez_vous_audit = result.data.date_rendez_vous_audit
            this.isLoading_update_rdv_technical_visit = false
            this.$refs['modal-meet-audit'].hide()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Responsable(s) de l'audit mystère enregistré",
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
          this.isLoading_update_rdv_technical_visit = false
        })
    },
    // -----------------------------------------------------------
    get_user_from_id(id) {
      const users_list = [
        ...this.options_mtca_admins,
        ...this.options_mtca_agents,
        ...this.options_ot_admins,
        ...this.options_ot_agents,
        ...this.options_auditors,
      ]
      for (let index = 0; index < users_list.length; index++) {
        const user = users_list[index]
        if (user.id === id) {
          user.fullname = `${user.lastname} ${user.firstname}, ${user.roles[0].title}, ${user.email}`
          return user
        }
      }
    },
    // -----------------------------------------------------------
    next_node_not_decision() {
      if (
        this.current_state &&
        this.current_state.next_decisions_options_count
      ) {
        return true
      }
      return false
    },
    curr_decision_node() {
      switch (this.current_state.status) {
        case 'rejected_registration':
          return { decision: 1, result: false }
          break
        case 'accepted_registration':
          return { decision: 1, result: true }
          break
        case 'rejected_classification':
          return { decision: 2, result: false }
          break
        case 'accepted_classification':
          return { decision: 2, result: true }
          break
        default:
          break
      }
    },
    treatment_unfinished() {
      if (
        this.current_state.status !== 'treatment_completed_successfully' &&
        this.current_state.status !== 'treatment_completed_failure'
      ) {
        return true
      }
      return false
    },
    variant_for_timeline(curr_status_name) {
      for (let index = 0; index < this.states.length; index++) {
        if (this.states[index].status === curr_status_name) {
          return this.states[index].class.split('-')[1]
        }
      }
    },
    object_change(data) {
      // console.log('data', data)
      const check_object = data.value
      this.isLoading_update_object = true
      this.action_updateFormulaire({
        id: this.current_demand_id,
        data: { check_object },
      })
        .then((result) => {
          if (result.status === 200) {
            // console.log('result', result.data)
            //this.validOutput = result.data.data;
            this.isLoading_update_object = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modification enregistrée',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
          this.isLoading_update_object = false
        })
    },
    object_check_change(data) {
      this.isLoading_update_check_status = true
      this.check_object = this.removecheck_object_attributes(data)
      this.action_updateFormulaire({
        id: this.current_demand_id,
        data: { check_object: this.check_object },
      })
        .then((result) => {
          if (result.status === 200) {
            this.validOutput.check_object = result.data.check_object
            this.isLoading_update_check_status = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modification enregistrée',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
          this.isLoading_update_check_status = false
        })
    },
    object_checklist_change(data) {
      this.isLoading_update_checklist = true
      // if (data.signature_file) {
      //   this.signature = data.signature_file;
      //   const blob = new Blob([data.signature_file], {
      //     type: data.signature_file.type,
      //   });
      //   // data.signature_file = blob;
      // }
      const formDataForSending = new FormData()
      formDataForSending.append('checklist_data', JSON.stringify(data))
      if (data.signature_file) {
        formDataForSending.append(
          'checklist_signature_file',
          data.signature_file
        )
      }
      this.checklist_object_for_print = data
      this.action_updateFormulaireFormData({
        id: this.current_demand_id,
        data: formDataForSending,
      })
        .then((result) => {
          if (result.status === 200) {
            // this.validOutput.checklist_object = result.data.checklist_data;
            this.isLoading_update_checklist = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modification enregistrée',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
          this.isLoading_update_checklist = false
        })
    },
    object_comments_change() {
      this.isLoading_update_comments = true
      // return;
      this.action_updateFormulaire({
        id: this.current_demand_id,
        data: { comments: this.validOutput.comments },
      })
        .then((result) => {
          if (result.status === 200) {
            // this.validOutput.check_object = result.data.check_object;
            this.isLoading_update_comments = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modification enregistrée',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
          this.isLoading_update_comments = false
        })
    },
    // function for auto éval --------------------------------------------------
    fonction_auto_eval() {
      this.evaluationResult = null
      const remappedChapter = this.fonction_auto_eval_remapChapter(
        this.request_have_auto_eval.data.chapters
      )
      const res = this.request_have_auto_eval
      res.data.chapters = remappedChapter
      this.evaluationResult = res
      this.$refs.modalAutoEval.show()
    },
    fonction_auto_eval_remapChapter(chapters) {
      const chaptersWithPercentage = chapters.map((chapter) => {
        const sectionsWithPercentage = chapter.sections.map((section) => {
          const totalQuestions = section.questions.length
          const selectedQuestions = section.questions.filter(
            (question) => question.selected_id !== null
          ).length
          const percentage = (selectedQuestions / totalQuestions) * 100
          return {
            ...section,
            percentage: percentage.toFixed(2), // Rounded to 2 decimal places
          }
        })
        return {
          ...chapter,
          sections: sectionsWithPercentage,
        }
      })
      return chaptersWithPercentage
    },
    // function for audit --------------------------------------------------------
    fonction_audit() {
      this.auditResult = null
      const remappedChapter = this.fonction_audit_remapChapter(
        this.request_have_audit.data.chapters
      )
      const res = this.request_have_audit
      res.data.chapters = remappedChapter
      this.auditResult = res
      this.$refs.modalAudit.show()
    },
    fonction_audit_remapChapter(chapters) {
      const chaptersWithPercentage = chapters.map((chapter) => {
        const sectionsWithPercentage = chapter.sections.map((section) => {
          const totalQuestions = section.questions.length
          const selectedQuestions = section.questions.filter(
            (question) => question.selected_id !== null
          ).length
          const percentage = (selectedQuestions / totalQuestions) * 100
          return {
            ...section,
            percentage: percentage.toFixed(2), // Rounded to 2 decimal places
          }
        })
        return {
          ...chapter,
          sections: sectionsWithPercentage,
        }
      })
      return chaptersWithPercentage
    },
    // function for visit --------------------------------------------------------
    fonction_visit(type) {
      this.visitResult = null
      this.request_technical_visit.map((item) => {
        if (item.type === type) {
          this.visitResult = item
          // console.log('this.visitResult', this.visitResult)
        }
      })
      // console.log('this.validOutput.data.code_demande', this.validOutput.data)

      if (type === 'technical') {
        this.finalPvForm = this.pvFormControleTechnique
        if (this.pvFormControleTechnique.check_object !== null) {
          if (this.validOutput.data.code_demande.substring(0, 4) == 'UEAV') {
            this.pvFormDataAV = this.pvFormControleTechnique.check_object
          } else {
            this.pvFormData = this.pvFormControleTechnique.check_object
          }
        }
      } else if (type === 'classment') {
        this.finalPvForm = this.pvFormControleClassement
        if (this.pvFormControleClassement.check_object !== null) {
          if (this.validOutput.data.code_demande.substring(0, 4) == 'UEAV') {
            this.pvFormDataAV = this.pvFormControleClassement.check_object
          } else {
            this.pvFormData = this.pvFormControleClassement.check_object
          }
        }
      } else if (type === 'audit') {
        this.finalPvForm = this.pvFormControleAudit
        if (this.pvFormControleAudit.check_object !== null) {
          if (this.validOutput.data.code_demande.substring(0, 4) == 'UEAV') {
            this.pvFormDataAV = this.pvFormControleAudit.check_object
          } else {
            this.pvFormData = this.pvFormControleAudit.check_object
          }
        }
      }
      if (this.validOutput.data.code_demande.substring(0, 4) == 'UEAV') {
        this.$refs.modalVisitAgenceVoyage.show()
      } else {
        this.$refs.modalVisit.show()
      }
    },
    fonction_visit_remapChapter(chapters) {
      const chaptersWithPercentage = chapters.map((chapter) => {
        const sectionsWithPercentage = chapter.sections.map((section) => {
          const totalQuestions = section.questions.length
          const selectedQuestions = section.questions.filter(
            (question) => question.selected_id !== null
          ).length
          const percentage = (selectedQuestions / totalQuestions) * 100
          return {
            ...section,
            percentage: percentage.toFixed(2), // Rounded to 2 decimal places
          }
        })
        return {
          ...chapter,
          sections: sectionsWithPercentage,
        }
      })
      return chaptersWithPercentage
    },
    // function for download -----------------------------------------------------------
    async downloadPDF(modal) {
      this.isDownloading = true
      this.forPrint = true
      if (modal == 'modalVisit') {
        await this.$refs.printablePvVisite.generatePdf()
        this.$refs.printablePvVisite.$once('hasDownloaded', () => {
          this.isDownloading = false
          if (modal) {
            this.$refs[modal].hide()
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Document téléchargé avec succès',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.forPrint = false
        })
      }
      if (modal == 'modalVisitGuide') {
        await this.$refs.printablePvGuide.generatePdf()
        this.$refs.printablePvGuide.$once('hasDownloaded', () => {
          this.isDownloading = false
          if (modal) {
            this.$refs[modal].hide()
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Document téléchargé avec succès',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.forPrint = false
        })
      }
      if (modal == 'modalVisitAgenceVoyage') {
        await this.$refs.printablePvAgenceVoyage.generatePdf()
        this.$refs.printablePvAgenceVoyage.$once('hasDownloaded', () => {
          this.isDownloading = false
          if (modal) {
            this.$refs[modal].hide()
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Document téléchargé avec succès',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.forPrint = false
        })
      }
      if (modal == 'modal-pv') {
        await this.$refs.printablePV.generatePdf()
        this.$refs.printablePV.$once('hasDownloaded', () => {
          this.isDownloading = false
          if (modal) {
            this.$refs[modal].hide()
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Document téléchargé avec succès',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.forPrint = false
        })
      }
      if (modal == 'modal-checklist') {
        await this.$refs.printableChecklist.generatePdf()
        this.$refs.printableChecklist.$once('hasDownloaded', () => {
          this.isDownloading = false
          if (modal) {
            this.$refs[modal].hide()
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Document téléchargé avec succès',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.forPrint = false
        })
      }
    },
    printInvoice() {
      window.print()
    },
    onProgress(event) {
      // console.log(`Processed: ${event} / 100`)
    },
    onDownloadedRequestFolder(event) {
      this.pdf = event
    },
    download_request() {
      this.$refs.html2Pdf.generatePdf()
    },
    async onDownloadBilan(uuid) {
      if (this.isLoading_download_bilan) return
      this.isLoading_download_bilan = true
      // this.action_downloadResult({ id: uuid })
      //   // this.action_searchTypeEvaluation()
      //   .then(async (response) => {
      //     this.isLoading_download_bilan = false;
      //   })
      //   .catch((err) => {
      //     this.isLoading_download_bilan = false;
      //     console.log("🔴🔴🔴", err);
      //   });
      try {
        const response = await fetch(
          `http://137.255.9.61:27800/api/auto-evaluations/download-bilan/${uuid}`
        )
        const blob = await response.blob()
        // ------------------------------------
        // const custom_blob = await this.convertReadableStreamToBlob(
        //   response.body
        // );
        // ------------------------------------
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'fichier.pdf'
        link.click()
        URL.revokeObjectURL(link.href)
        this.isLoading_download_bilan = false
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors du téléchargement du fichier :",
          error
        )
        return null
      }
    },
    async onDownloadBilanDetailed(uuid) {
      if (this.isLoading_download_bilan) return
      this.isLoading_download_bilan = true
      // this.action_downloadQa({ id: uuid })
      //   .then(async (response) => {
      //     this.isLoading_download_bilan = false;
      //   })
      //   .catch((err) => {
      //     console.log("🔴🔴🔴", err);
      //     this.isLoading_download_bilan = false;
      //   });
      try {
        const response = await fetch(
          `http://137.255.9.61:27800/api/auto-evaluations/download/${uuid}`
        )
        const blob = await response.blob()
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'fichier.pdf'
        link.click()
        URL.revokeObjectURL(link.href)
        this.isLoading_download_bilan = false
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors du téléchargement du fichier :",
          error
        )
        return null
      }
    },
    removeItemById(arr, id) {
      return arr.filter((item) => item.id !== id)
    },
    updateRequest() {
      this.isLoading_meeting_informations = true
      this.action_updateFormulaire({
        id: this.current_demand_id,
        data: {
          meeting_with_applicant_info: this.meeting_with_applicant_info,
          meeting_with_applicant_moment: moment().format(
            'MMMM Do YYYY, h:mm:ss a'
          ),
        },
      })
        .then((result) => {
          if (result.status === 200) {
            this.isLoading_meeting_informations = false
            this.$refs.modalMeetingWithApplicant.hide()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modification enregistrée',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
          this.isLoading_meeting_informations = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/base/pages/app-invoice.scss';

.horizontal-scroll {
  display: inline-block;
  overflow-x: auto;
  white-space: nowrap;
}

.main-grid-file-display {
  display: grid;
  grid-template-columns: auto 750px;
  column-gap: 30px;
  position: relative;

  .options-sidebar {
    overflow: auto;
    height: 90vh;
    padding-bottom: 59px;
    position: fixed;
    right: 1.5rem;
    width: 750px;
  }
}

.main-grid {
  display: grid;
  grid-template-columns: auto;
  column-gap: 30px;
  position: relative;

}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .main-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 30px;
    position: relative;

    .options-sidebar {
      overflow: auto;
      height: unset;
      position: unset;
      width: unset;
    }
  }
}

.d-content {
  display: contents;
}

#sidebar-wrapper {
  position: fixed;
  right: 250px;
  width: 100%;
  height: 100%;
  margin-right: -90%;
  overflow-y: auto;

  .sidecard {
    margin: 0 0 800px 0;
  }
}

@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}

@import '@core/scss/vue/libs/vue-flatpicker.scss';

.pdf-item {
  page-break-inside: avoid;
}

.size {
  font-size: 15px;
}

.underline {
  text-decoration: underline;
}
.head_bg {
  background-color: #b8cce4 !important;
}
</style>
