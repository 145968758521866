import demandesService from '@/services/http/demandes.service'

export default {
  namespaced: true,
  state: {
    rdv_params: {}
  },
  getters: {
    getRdvParams: (state) => state.rdv_params
  },
  mutations: {
    setRdvParams: (state, payload) => state.rdv_params = payload
  },
  actions: {
    async fetchDemandes({ rootState }, data) {
      const res = await demandesService.fetchTypeDemandes(data)
      return res
    },

    async updateTypeDemandes({ rootState }, data) {
      const res = await demandesService.updateTypeDemandes(data)
      return res
    },

    async getDemmande({ rootState }, data) {
      const res = await demandesService.getDemmande(data)
      return res
    }
  },
}
