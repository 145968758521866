<template>
  <table ref="tableToExportInExcel">
    <template v-for="(star, starIndex) in ['1', '2', '3', '4', '5']">
      <tr>
        <th colSpan="8">
          ◼◼◼ {{ Number(star) > 1 ? star + ' Etoiles' : '1 Etoile' }} 🔸
          {{ formattedResults[star].is_eligible ? 'ELIGIBLE' : 'NON ELIGIBLE' }}
          ◼◼◼◼
        </th>
      </tr>
      <!-- <tbody v-for="(chap, chapIndex) in formattedResults[star].chapters">
        <tr>
          <th colSpan="4">◼ {{ chap.label }}</th>
          <th colSpan="2">{{ chap.percents.major.toFixed(2) + ' %' }}</th>
          <th colSpan="2">{{ chap.percents.minor.toFixed(2) + ' %' }}</th>
        </tr>
        <tr
          v-for="(section, sectionIndex) in chap.sections"
          :key="sectionIndex"
        >
          <td colSpan="4">{{ section.label }}</td>
          <td colSpan="2">{{ section.percents.major.toFixed(2) + ' %' }}</td>
          <td colSpan="2">{{ section.percents.minor.toFixed(2) + ' %' }}</td>
        </tr>
      </tbody> -->
      <tbody v-for="(chap, chapIndex) in formattedResults[star].chapters">
        <tr>
          <td colSpan="8"></td>
        </tr>
        <tr>
          <th colSpan="4">◼ {{ chap.label }}</th>
          <th colSpan="2">{{ formatPercentage(chap.percents.major) }}</th>
          <th colSpan="2">{{ formatPercentage(chap.percents.minor) }}</th>
        </tr>
        <tr
          v-for="(section, sectionIndex) in chap.sections"
          :key="sectionIndex"
        >
          <td colSpan="4">{{ section.label }}</td>
          <td colSpan="2">{{ formatPercentage(section.percents.major) }}</td>
          <td colSpan="2">{{ formatPercentage(section.percents.minor) }}</td>
        </tr>
        <tr>
          <td colSpan="8"></td>
        </tr>
      </tbody>
      <tr>
        <td colSpan="8"></td>
      </tr>
      <tr>
        <td colSpan="8"></td>
      </tr>
      <tr>
        <td colSpan="8"></td>
      </tr>
    </template>
  </table>
</template>

<script>
export default {
  // mounted() {
  //   this.$emit('hasMounted');
  // },
  props: {
    // classementNumber: {
    //   type: String,
    //   required: true,
    //   default: '1',
    // },
    results: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatPercentage(value) {
      // Format numeric value to fixed 2 decimal places
      return (value * 100).toFixed(2) + ' %';
    },
  },
  computed: {
    labelComputed() {
      const classementNumb = Number(this.classementNumber);
      if (classementNumb > 1) return `${classementNumb} Etoiles`;
      return '1 Etoile';
    },
    formattedResults() {
      let res = this.results;
      const updatedData = {};

      for (const key in res) {
        if (res.hasOwnProperty(key)) {
          const newKey = key.replace(' Etoile', '').replace('s', '');
          updatedData[newKey] = res[key];
        }
      }
      return updatedData;
    },
  },
};
</script>
