<template>
  <b-sidebar
    id="sidebar-send-invoice"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Envoyer par Mail</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <div>
        <!-- Body -->
        <b-form class="p-2" @submit.prevent>
          <!-- From -->
          <!-- <b-form-group label="De" label-for="from">
            <b-form-input
              id="from"
              v-model="sendInvoiceData.from"
              trim
              type="email"
            />
          </b-form-group> -->

          <!-- To -->
          <b-form-group label="A" label-for="to">
            <b-form-input v-model="sendInvoiceData.to" trim type="email" />
          </b-form-group>

          <!-- Cc -->
          <b-form-group label="Cc" label-for="to">
            <b-form-tags
              v-model="sendInvoiceData.cc"
              placeholder=" "
              class=""
              separator=" ,;"
            />
          </b-form-group>

          <!-- Bcc -->
          <b-form-group label="Bcc" label-for="to">
            <b-form-tags
              v-model="sendInvoiceData.bcc"
              placeholder=" "
              class=""
              separator=" ,;"
            />
          </b-form-group>

          <!-- Subject -->
          <b-form-group label="Objet" label-for="Subject">
            <b-form-input id="subject" v-model="sendInvoiceData.subject" trim />
          </b-form-group>

          <!-- Message -->
          <b-form-group label="Message" label-for="message">
            <b-form-textarea
              id="message"
              v-model="sendInvoiceData.message"
              rows="12"
              trim
            />
          </b-form-group>

          <!-- File/Attachement -->
          <b-badge
            variant="light-primary"
            v-if="pdf && sendInvoiceData.attachedFileName"
          >
            <feather-icon icon="LinkIcon" />
            <span class="ml-50">{{ sendInvoiceData.attachedFileName }}</span>
          </b-badge>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="send()"
              :disabled="pdf ? false : true"
            >
              <b-spinner v-if="loadingSend" small />
              Envoyer
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BBadge,
  BSpinner,
  BFormTags,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { mapActions } from "vuex";
import TemplateMail from "./template";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import localstorageService from "@/services/localstorage/localstorage.service";

export default {
  props: {
    formData: {
      type: Object,
      default: {},
    },
    pdf: {
      type: Blob,
      default: null,
    },
    currentSubject: {
      type: String,
      default: "",
    },
    fileName: {
      type: String,
      default: "",
    },
  },
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BBadge,
    BSpinner,
    BFormTags,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  setup() {
    // let userData = localstorageService.getUserData();
    // let sendInvoiceData = ref({
    //   from: "mtca.noreply@gouv.bj",
    //   senderFullname: "MTCA NoReply",
    //   to: this.formData.email || "",
    //   subject: "",
    //   message: "",
    // });
    // return {
    //   sendInvoiceData,
    // };
  },
  data() {
    return {
      loadingSend: false,
      filename: "",
      sendInvoiceData: {
        attachedFileName: "",
        cc: [],
        bcc: [],
      },
    };
  },
  watch: {
    formData: {
      immediate: true,
      deep: false,
      handler(val, old) {
        // console.log("formData val::: 🟢🔴⭕️", val);
        if (val) {
          this.sendInvoiceData.to = val.email;
        }
      },
    },
    pdf: {
      immediate: false,
      deep: false,
      handler(val, old) {
        // console.log("pdf val::: 🟢🔴⭕️", val);
        if (val) {
          this.sendInvoiceData.attachedFile = val;
        }
      },
    },
    fileName: {
      immediate: true,
      deep: false,
      handler(val, old) {
        if (val) {
          // console.log("fileName val::: 🟢🔴⭕️", val);
          this.sendInvoiceData.attachedFileName = val + ".pdf";
        }
      },
    },
    currentSubject: {
      immediate: true,
      deep: false,
      handler(val, old) {
        if (val) {
          // console.log("currentSubject val::: 🟢🔴⭕️", val);
          this.sendInvoiceData.subject = val;
        }
      },
    },
  },
  mounted() {
    this.sendInvoiceData = {
      subject: "",
      ...this.sendInvoiceData,
      from: "mtca.noreply@gouv.bj",
      senderFullname: "MTCA NoReply",
      message: "",
    };
  },
  methods: {
    ...mapActions("utils", {
      sendMail: "sendMail",
    }),
    // -----------------------------------
    send() {
      if (this.loadingSend) return;
      // console.log("sendInvoiceData::: ⭕️", this.sendInvoiceData);

      if (
        this.sendInvoiceData.to === "" ||
        this.sendInvoiceData.subject === "" ||
        this.sendInvoiceData.message === ""
      ) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Veuillez renseigner tous les champs.",
            // icon: 'CheckIcon',
            variant: "danger",
            // text: 'Bienvenue !',
          },
        });
        return;
      }
      this.loadingSend = true;
      // this.sendInvoiceData.attachedFile = this.pdf ? this.pdf : null;
      const curr_file = new File(
        [this.sendInvoiceData.attachedFile],
        this.sendInvoiceData.attachedFileName
      );
      // const data = {
      //   email: this.sendInvoiceData.to,
      //   subject: this.sendInvoiceData.subject,
      //   object: "",
      //   content: this.sendInvoiceData.message,
      //   cc: this.sendInvoiceData.cc,
      //   bcc: this.sendInvoiceData.bcc,
      //   medias: [curr_file],
      // };

      let formDataForSending = new FormData();
      formDataForSending.append("bcc", this.sendInvoiceData.bcc);
      formDataForSending.append("cc", this.sendInvoiceData.bcc);
      formDataForSending.append("content", this.sendInvoiceData.message);
      formDataForSending.append("email", this.sendInvoiceData.to);
      formDataForSending.append("media", [curr_file]);
      formDataForSending.append("object", "");
      formDataForSending.append("subject", this.sendInvoiceData.subject);

      // for (const pair of formDataForSending.entries()) {
      //   console.log(`${pair[0]}: ${pair[1]}`);
      // }
      // return;

      this.sendMail(formDataForSending)
        .then((res) => {
          console.log("sendMail res: ---------------------- 🟢", res);
          if (res.status == 200) {
            this.loadingSend = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Mail envoyé",
                // icon: 'CheckIcon',
                variant: "success",
                // text: 'Bienvenue !',
              },
            });
            this.hide;
          } else {
            this.loadingSend = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Erreur d'envoi",
                // icon: 'CheckIcon',
                variant: "danger",
                // text: 'Bienvenue !',
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
