import { BaseService } from "./base.service";

export class DemandeService extends BaseService {
  constructor() {
    super();
  }

  fetchDemandes() {
    // console.log('fetchTypeDemandes');
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get("/demandes")
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  getDemmande(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/demandes/${id}`, {})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  updateTypeDemandes(object) {
    const { id, data } = object;
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/demandes/${id}`, data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}

export default new DemandeService();
