<template>
  <div>
    <b-table-simple
      v-for="(chap, chapIndex) in results.sections"
      :key="chapIndex"
      hover
      fixed
      head-variant="dark"
      responsive
      small
    >
      <b-thead>
        <b-tr>
          <b-th colspan="3" class="text-center">
            {{ chap.title }}
          </b-th>
        </b-tr>
        <b-tr>
          <b-th colspan="2"> Questions </b-th>
          <b-th class="text-center"> Appreciations </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(section, sectionIndex) in chap.questions"
          :key="sectionIndex"
        >
          <b-td colspan="2">
            {{ section.title }}
          </b-td>
          <b-td class="text-center"
            ><b-badge
              :variant="
                section.check && section.check == true
                  ? 'success'
                  : section.check && section.check == false
                  ? 'danger'
                  : 'secondary'
              "
            >
              {{
                section.check && section.check == true
                  ? 'Oui'
                  : section.check && section.check == false
                  ? 'Non'
                  : 'Non Concerné'
              }}</b-badge
            >
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <!-- <div
      v-if="(currentTableIndex + 1) % 3 === 0"
      class="html2pdf__page-break"
    ></div> -->
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BBadge,
  },
  // data: () => ({
  //   currentTableIndex: -1,
  // }),

  props: {
    results: {
      type: Object,
      required: true,
    },
  },

  // watch: {
  //   formattedResults: {
  //     handler() {
  //       this.currentTableIndex = -1;
  //     },
  //     deep: true,
  //   },
  // },

  computed: {
    formattedResults() {
      const res = this.results
      const updatedData = {}

      for (const key in res) {
        if (res.hasOwnProperty(key)) {
          const newKey = key.replace(' Etoile', '').replace('s', '')
          updatedData[newKey] = res[key]
        }
      }
      return updatedData
    },
  },
}
</script>
