import visiteService from '@/services/http/visites.service';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    async updateVisite({ rootState }, data) {
      const res = await visiteService.updateVisite(data);
      return res;
    },

    async searchVisites({ rootState }, data) {
      const res = await visiteService.searchVisites(data);
      return res;
    },

  },
};
